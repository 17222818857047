// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';

const CardGallery = styled.div``;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: -26px; /* remove spacing bottom */
  margin-left: 0;
  position: relative;
  top: -26px; /* align with flickity controls */
  width: 100%;

  ${vars.breakpoint.medium`
    margin-left: 0;
    padding: 0 20px;
    width: 100%;
  `}
`;

const GalleryButton = styled.button`
  svg {
    fill: ${vars.colors.blue500};
    height: 20px;
    width: 20px;
  }
`;

CardGallery.Button = GalleryButton;
CardGallery.Actions = Actions;

export default CardGallery;
