// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import StyledText from '../../styles/components/Text';

const Text = ({ text, modifiers }) => {
  return <StyledText modifiers={modifiers}>{text}</StyledText>;
};

Text.propTypes = {
  modifiers: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
};

Text.defaultProps = {
  modifiers: {},
};

export default Text;
