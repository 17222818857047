import * as vars from '../global/vars';

export default `
  .flickity-page-dots {
    background-color: ${vars.colors.blue500}
    display: flex;
    height: 2px
    justify-content: space-evenly;
    line-height: 1;
    list-style: none;
    margin: ${vars.spacing.xLarge} auto ${vars.spacing.small};
    max-width:  ${vars.grid.maxWidthSmall};
    width: 80%;

    @media (min-width: ${vars.bp.small}) {
      width: 85%;
    }

    @media (min-width: ${vars.grid.maxWidth}) {
      width: 100%;
    }

    .dot {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      position: relative;
      transform: translate(-100%, 0);
      visibility: hidden;
      z-index: 1;

      .is-reverse & {
        transform: translate(100%, 0);
      }

      &:before {
        background-color: ${vars.colors.blue500};
        border-radius: 100%;
        content: '';
        display: block;
        height: 20px;
        position: relative;
        width: 20px;
        z-index: 2;

        @media (min-width: ${vars.bp.medium}) {
          height: 30px;
          width: 30px;
        }
      }

      &:after {
        background-color: ${vars.colors.white};
        background-image: url(${vars.svg.curve});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        bottom: -1px;
        content: '';
        display: block;
        height: 30px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 80px;
        z-index: 1;
      }
      
      &.is-selected {
        transform: translate(0, 0);
        transition: transform 400ms ${vars.global.easing};
        visibility: visible;
      }
    }
  }

  .flickity-prev-next-button {
    bottom: 0;
    height: ${vars.spacing.small};
    position: absolute;
    transform: translate(0, 50%);
    width: ${vars.spacing.small};

    svg {
      fill: ${vars.colors.blue500};
      height: ${vars.spacing.small};
      width: ${vars.spacing.small};
    }

    &.next {
      right: ${vars.spacing.base};

      @media (min-width: ${vars.grid.maxWidth}) {
        right: calc((100% - ${vars.grid.maxWidth}) / 2 + ${vars.spacing.xLarge});
      }
    }

    &.previous {
      left: ${vars.spacing.base};

      @media (min-width: ${vars.grid.maxWidth}) {
        left: calc((100% - ${vars.grid.maxWidth}) / 2 + ${vars.spacing.xLarge});
      }
    }
  }
`;
