// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import ImageLoader from '../ImageLoader';
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';

const LogoBlock = ({ logos, storybook }) => {
  return (
    <Section modifiers={{ center: true, smallPadding: true }}>
      <Container>
        <Title type="h3" styleType="h6" modifiers={{ darkGrey: true, largeMargin: true }}>
          Trusted by leading organizations around the world
        </Title>
        <Grid modifiers={{ justifyCenter: true }}>
          {logos.map(logo => {
            const { logo: image } = logo;
            const imageUrl = image && image[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${image[0].url}`;

            return (
              <Grid.Item id={logo.id} modifiers={{ cols: 4, colsSmall: 3, colsMedium: 2 }}>
                <Grid.Inner>
                  <ImageLoader imageUrl={imageUrl} />
                </Grid.Inner>
              </Grid.Item>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

LogoBlock.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  storybook: PropTypes.bool,
};

LogoBlock.defaultProps = {
  storybook: false,
};

export default LogoBlock;
