// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';

const Inner = styled.div`
  height: calc(100% - ${vars.grid.gutterValue}px);
  margin: ${vars.grid.gutterValue / 2}px;

  @media print {
    height: auto;
  }

  ${props =>
    getModifier(props, 'noMargin') &&
    `
      margin: 0;
  `};

  ${props =>
    getModifier(props, 'noBottomMargin') &&
    `
      margin: 0 ${vars.grid.gutterValue / 2}px;
  `};

  ${props =>
    getModifier(props, 'noBottomMargin') &&
    vars.breakpoint.medium`
      margin: 0 ${vars.grid.gutterValue / 2}px;
  `};

  ${props =>
    getModifier(props, 'largeBottomMargin') &&
    vars.breakpoint.medium`
      margin: 0 ${vars.grid.gutterValue / 2}px ${vars.spacing.xLarge};
  `};

  ${props =>
    getModifier(props, 'storybook') &&
    `
      background-color: ${vars.colors.grey200};
      height: 100px;
  `};

  ${props =>
    getModifier(props, 'storybook') &&
    vars.breakpoint.medium`
      height: 100vh;
  `};
`;

export default Inner;
