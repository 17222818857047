// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

import getModifier from '../../../utils/getModifier';

const Text = styled.p`
  color: ${vars.colors.grey500};
  margin-bottom: ${vars.spacing.base};
  opacity: 0.5;

  ${props =>
    getModifier(props, 'regular') &&
    `
    color: ${vars.colors.black};
    opacity: 1;

  `}
`;

export default Text;
