// Import Dependencies
import styled from 'styled-components';

// Import elements
import Label from './Label';
import Button from './Button';

// Import Vars
import * as vars from '../../global/vars';

const PlanSelector = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${vars.spacing.midLarge};

  ${vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.large};
  `}
`;

PlanSelector.Label = Label;
PlanSelector.Button = Button;

export default PlanSelector;
