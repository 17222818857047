// Import Dependencies
import styled from 'styled-components';

import getModifier from '../../../utils/getModifier';

const Fields = styled.ol`
  max-width: 500px;
  padding: 0;

  ${props =>
    getModifier(props, 'fullWidth') &&
    `
    max-width: 100%;
  `};
`;

export default Fields;
