// Import Dependencies
import styled from 'styled-components';

// Import Globals
import typography from '../../global/typography';
import * as vars from '../../global/vars';
import getModifier from '../../../utils/getModifier';

const Title = styled.h3`
  ${typography.heading4};
  font-weight: ${vars.fontWeight.semibold};
  color: ${vars.colors.black};

  ${props =>
    getModifier(props, 'dark') &&
    `
      color: ${vars.colors.white};
  `};
`;

export default Title;
