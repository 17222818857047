// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledFieldHint from '../../styles/components/FieldHint';
import InfoIcon from '../Icons/Info';

class FieldHint extends React.Component {
  state = {
    open: false,
  };

  tooltipRef = React.createRef();

  componentDidUpdate() {
    const { open } = this.state;
    if (open) {
      document.addEventListener('click', this.handleDocumentClick);
    } else {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('mouseover', this.handleHover);
  }

  handleHover = () => {
    const { open } = this.state;

    if (!open) {
      this.setState({ open: true });
    }
  };

  handleHoverOut = () => {
    setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };

  handleToggle = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDocumentClick = e => {
    if (!this.tooltipRef.current.contains(e.target)) {
      this.handleClose();
    }
  };

  render() {
    const { children, hover, render, ...props } = this.props;
    const { open } = this.state;

    return (
      <StyledFieldHint.Wrapper ref={this.tooltipRef} {...props}>
        <StyledFieldHint
          open={open}
          onClick={this.handleToggle}
          onFocus={hover ? this.handleHover : null}
          onMouseOut={hover ? this.handleHoverOut : null}
          onBlur={hover ? this.handleHoverOut : null}
          onMouseOver={hover ? this.handleHover : null}
          {...props}
        >
          {render ? render() : <InfoIcon />}
        </StyledFieldHint>
        <StyledFieldHint.Tooltip open={open} {...props}>
          {children}
        </StyledFieldHint.Tooltip>
      </StyledFieldHint.Wrapper>
    );
  }
}

FieldHint.propTypes = {
  children: PropTypes.node.isRequired,
  render: PropTypes.func,
  hover: PropTypes.bool,
};

FieldHint.defaultProps = {
  render: null,
  hover: false,
};

export default FieldHint;
