const getFieldError = (value, validate, formData) => validate.reduce((a, validateFunction) => {
  const error = validateFunction(value, formData);
  if (error) {
    if (a && a.length) {
      return [...a, error];
    }
    return [error];
  }
  return a;
}, null);

export default getFieldError;
