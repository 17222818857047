// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Plans from '../Plans';

// Import Styled Components

// Import Utilities
import { transformPlanDataToOptions } from '../../utils';

const PlansBlock = ({ plans }) => {
  return <Plans planData={plans} options={transformPlanDataToOptions(plans)} />;
};

PlansBlock.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PlansBlock;
