/* eslint-disable react/prop-types */
// Import Dependencies
import React, { useState } from 'react';
import { Field } from 'formik';

// Import Styled Components
import StyledTextInput from '../../styles/components/TextInput';
import StyledField from '../../styles/components/Field';
import DownIcon from '../Icons/Down';

const TextField = ({ name, caption, placeholder, children, ...props }) => {
  const [hasFocus, setHasFocus] = useState(false);
  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => {
        const showError = errors[field.name] && touched[field.name];
        return (
          <StyledField.Wrapper>
            <StyledTextInput hasFocus={hasFocus} hasError={showError}>
              <select
                {...field}
                {...props}
                id={field.name}
                onFocus={() => setHasFocus(true)}
                onBlur={e => {
                  field.onBlur(e);
                  setHasFocus(false);
                }}
              >
                <option value="">{placeholder}</option>
                {children}
              </select>
              <StyledField.Icon>
                <DownIcon />
              </StyledField.Icon>
            </StyledTextInput>
            {showError && <StyledField.InlineError htmlFor={field.name}>{errors[field.name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption htmlFor={field.name}>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </Field>
  );
};

export default TextField;
