// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Dashboard = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M400 116.7c-27.6 0-50 22.4-50 50 0 10.2 3.1 19.7 8.4 27.7l-43.6 58.1c-4.7-1.5-9.6-2.5-14.8-2.5-9.3 0-17.9 2.7-25.4 7.2L246.7 234c2-5.4 3.3-11.2 3.3-17.3 0-27.6-22.4-50-50-50s-50 22.4-50 50c0 9.1 2.6 17.4 6.8 24.8l-38.9 45.4c-5.6-2.2-11.6-3.5-17.9-3.5-27.6 0-50 22.4-50 50s22.4 50 50 50 50-22.4 50-50c0-9.1-2.6-17.4-6.8-24.8l38.9-45.4c5.6 2.2 11.6 3.5 17.9 3.5 9.3 0 17.9-2.7 25.4-7.2l27.9 23.2c-2 5.4-3.3 11.2-3.3 17.3 0 27.6 22.4 50 50 50s50-22.4 50-50c0-10.2-3.1-19.7-8.4-27.7l43.6-58.1c4.7 1.5 9.6 2.5 14.8 2.5 27.6 0 50-22.4 50-50s-22.4-50-50-50zM100 350c-9.2 0-16.7-7.5-16.7-16.7s7.5-16.7 16.7-16.7 16.7 7.5 16.7 16.7S109.2 350 100 350zm100-116.7c-9.2 0-16.7-7.5-16.7-16.7s7.5-16.7 16.7-16.7 16.7 7.5 16.7 16.7c0 9.3-7.5 16.7-16.7 16.7zm100 83.4c-9.2 0-16.7-7.5-16.7-16.7s7.5-16.7 16.7-16.7 16.7 7.5 16.7 16.7-7.5 16.7-16.7 16.7zm100-133.4c-9.2 0-16.7-7.5-16.7-16.7s7.5-16.7 16.7-16.7 16.7 7.5 16.7 16.7c0 9.3-7.5 16.7-16.7 16.7z" />
  </Icon>
);

export default Dashboard;
