// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import elements
import AccordionSection from './AccordionSection';
import AccordionSectionTitle from './AccordionSectionTitle';
import AccordionSectionContent from './AccordionSectionContent';

const Accordion = styled.div`
  background-color: ${vars.colors.grey500};

  ${props =>
    getModifier(props, 'whiteBackground') &&
    `
    background-color: ${vars.colors.white};
  `}
`;

// Set elements
Accordion.Section = AccordionSection;
Accordion.Title = AccordionSectionTitle;
Accordion.Content = AccordionSectionContent;

export default Accordion;
