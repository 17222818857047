// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

const TextBig = styled.p`
  color: ${vars.colors.grey400};
  font-size: ${vars.fontSize.medium};
`;

export default TextBig;
