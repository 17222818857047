// Import dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Title from '../Title';
import * as Icon from '../Icons';

// Import Styled Components
import StyledAccordion from '../../styles/components/Accordion';
import Wysiwyg from '../../styles/components/Wysiwyg';
import cleanHtml from '../../utils/cleanHtml';

export class AccordionSection extends PureComponent {
  state = {
    isExpanded: false,
    contentHeight: 0,
  };

  setRef = el => {
    this.inner = el;
  };

  handleToggle = e => {
    e.preventDefault();

    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
      contentHeight: this.inner ? this.inner.clientHeight : 0,
    }));
  };

  render() {
    const { role, id, accordionHeading, body, modifiers, ...props } = this.props;
    const { isExpanded, contentHeight } = this.state;
    const currentHeight = isExpanded ? contentHeight : 0;
    const ariaExpanded = !!isExpanded;

    return (
      <StyledAccordion.Section
        role="tablist"
        aria-multiselectable="true"
        modifiers={{ expanded: isExpanded, ...modifiers }}
        onClick={this.handleToggle}
        {...props}
      >
        <StyledAccordion.Title role="tab" id={id} aria-expanded={ariaExpanded} aria-selected={ariaExpanded} modifiers={{ ...modifiers }}>
          <Title type="h3" styleType="h4" modifiers={{ white: true, noMargin: true, ...modifiers }}>
            {accordionHeading}
          </Title>
          <Icon.Down modifiers={{ expanded: isExpanded }} />
        </StyledAccordion.Title>

        <StyledAccordion.Content
          role="tabpanel"
          aria-hidden={!ariaExpanded}
          aria-labelledby={id}
          style={{ height: `${currentHeight}px` }}
        >
          {body && body.content && (
            <Wysiwyg ref={this.setRef} dangerouslySetInnerHTML={{ __html: cleanHtml(body.content) }} />
          )}
        </StyledAccordion.Content>
      </StyledAccordion.Section>
    );
  }
}

AccordionSection.propTypes = {
  role: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  accordionHeading: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  modifiers: PropTypes.shape({}),
};

AccordionSection.defaultProps = {
  role: null,
  modifiers: {},
};

export default AccordionSection;
