// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Calendar = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g fill="currentColor">
      <path d="M374.8 118H346V94c0-13.3-10.7-24-24-24s-24 10.7-24 24v24h-96V94c0-13.3-10.7-24-24-24s-24 10.7-24 24v24h-28.8c-23.8 0-43.2 19.9-43.2 44.3v223.4c0 24.4 19.4 44.3 43.2 44.3h249.6c23.8 0 43.2-19.9 43.2-44.3V162.3c0-24.4-19.4-44.3-43.2-44.3zm-4.8 48v48H130v-48h240zM130 382V262h240v120H130z" />
    </g>
  </Icon>
);

export default Calendar;
