// Import Dependencies
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';
import ContactSupport from '../ContactSupport';

// Import Styled Components
import StyledPlanCard from '../../styles/components/PlanCard';
import Grid from '../../styles/objects/Grid';

import Wysiwyg from '../../styles/components/Wysiwyg';

import { HideOnMobile } from '../../styles/global/responsive';
import cleanHtml from '../../utils/cleanHtml';

class PlanCard extends PureComponent {
  // Handle form change
  handleClick = () => {
    const { planCode, code, price, onClick, maxParticipants } = this.props;
    onClick({ planCode: planCode || code, amount: price, maxParticipants });
  };

  renderPrice = () => {
    const { price } = this.props;
    const priceInt = parseInt(price, 10);
    const priceLabel = priceInt && priceInt < 999000 ?
      priceInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "Bespoke";
        
    return (
      <Fragment>
        <StyledPlanCard.Price>
          <span>$</span>
          {priceLabel}
        </StyledPlanCard.Price>
        <HideOnMobile>
          <StyledPlanCard.TextBig>{priceInt && priceInt < 999000 ? 'Annually' : ''}</StyledPlanCard.TextBig>
        </HideOnMobile>
      </Fragment>
    );
  };

  render() {
    const { id, label, content, isProcessing, onClick, selectLink, upgradeIsDisabled, selectedPlan } = this.props;

    const isCurrentPlan = id === selectedPlan;

    return (
      <StyledPlanCard>
        <Grid>
          <Grid.Item modifiers={{ colsSmall: 6, borderRight: true }}>
            <Grid.Inner>
              <StyledPlanCard.Col modifiers={{ first: true }}>
                <HideOnMobile>
                  <StyledPlanCard.Title>{`${label}`}</StyledPlanCard.Title>
                </HideOnMobile>
                {content && (
                  <StyledPlanCard.Wysiwyg>
                    <Wysiwyg dangerouslySetInnerHTML={{ __html: cleanHtml(content) }} />
                  </StyledPlanCard.Wysiwyg>
                )}
              </StyledPlanCard.Col>
            </Grid.Inner>
          </Grid.Item>
          <Grid.Item modifiers={{ colsSmall: 6 }}>
            <Grid.Inner>
              <StyledPlanCard.Col modifiers={{ colorGrey: true, fontLarger: true }}>
                {upgradeIsDisabled ? (
                  <Fragment>
                    {this.renderPrice()}
                    {!isCurrentPlan && (
                      <ContactSupport
                        message="Contact us if you wish to downgrade your plan"
                        email="hello@workabilityindex.com.au"
                        support="Email support"
                      />
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {this.renderPrice()}
                    <Button
                      disabled={isProcessing}
                      modifiers={{ isProcessing }}
                      onClick={onClick ? this.handleClick : undefined}
                      href={selectLink || selectLink}
                    >
                      Select plan
                    </Button>
                  </Fragment>
                )}
              </StyledPlanCard.Col>
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </StyledPlanCard>
    );
  }
}

PlanCard.propTypes = {
  code: PropTypes.string,
  content: PropTypes.string,
  upgradeIsDisabled: PropTypes.bool,
  isProcessing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  planCode: PropTypes.string,
  selectedPlan: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxParticipants: PropTypes.string,
  price: PropTypes.number.isRequired,
  selectLink: PropTypes.string,
};

PlanCard.defaultProps = {
  id: null,
  code: null,
  content: null,
  upgradeIsDisabled: false,
  planCode: null,
  maxParticipants: null,
  selectLink: null,
  selectedPlan: null,
};

export default PlanCard;
