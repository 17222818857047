import * as vars from '../global/vars';

import flickityControls from './flickityControls';

export default `
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  .flickity {
    height: 0;
    opacity: 0;
    position: relative;
    transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s;

    &.is-loaded {
      height: auto;
      opacity: 1;
      overflow: visible;
      position: static;
    }
  }

  .flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus { outline: none; }

  .flickity-viewport {
    position: relative;
    height: 100%;
    margin: 60px 0;

    @media (min-width: ${vars.grid.maxWidth}) {
      margin: ${vars.spacing.large} 0;
    }

    &:before {
      background-image: url(${vars.svg.dot});
      background-size: 12px;
      bottom: -${vars.spacing.large};
      content: '';
      left: 50%;
      max-width: ${vars.grid.maxWidthSmall};
      opacity: 0.1;
      position: absolute;
      top: -${vars.spacing.large};
      transform: translate(-50%, 0);
      width: 100%;
      z-index: -1;
    }
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  } 

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
            tap-highlight-color: transparent;
    -webkit-user-select: none;
      -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  /* ---- page dots ---- */
  ${flickityControls}
`;
