// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';
import getModifier from '../../../utils/getModifier';

const Col = styled.div`
  height: 100%;
  text-align: center;

  ${vars.breakpoint.medium`
    padding-left: ${toRems('85px')};
    padding-right: ${toRems('85px')};
  `}

  ${props =>
    getModifier(props, 'first') &&
    `
      text-align: left;
  `};

  ${props =>
    getModifier(props, 'first') &&
    vars.breakpoint.medium`
    border-right: 2px solid ${vars.colors.grey200}
    padding-left: 0;
    padding-right: ${toRems('60px')};
  `};

  ${props => getModifier(props, 'colorGrey') && `color: ${vars.colors.grey400}`};
`;

export default Col;
