// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Info = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g fill="currentColor">
      <path d="M250 430c-99.3 0-180-80.8-180-180S150.7 70 250 70s180 80.8 180 180-80.7 180-180 180zm0-320c-77.2 0-140 62.8-140 140s62.8 140 140 140 140-62.8 140-140-62.8-140-140-140z" />
      <path d="M250 350c-11.1 0-20-9-20-20v-80c0-11 8.9-20 20-20s20 9 20 20v80c0 11-8.9 20-20 20z" />
      <circle cx="250" cy="190" r="20" />
    </g>
  </Icon>
);

export default Info;
