// Import Dependencies
import React from 'react';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';

const HeaderLogo = ({ ...props }) => {
  return (
    <StyledHeader.Logo {...props}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 526 119" {...props}>
        <path
          d="M52.0531,98.1102 L52.0531,118.1522 L44.5561,118.1522 C34.2861,118.1522 26.4191,115.6672 20.9531,110.6982 C15.4871,105.7292 12.7541,97.4472 12.7541,85.8532 L12.7541,41.8232 L0.0001,41.8232 L0.0001,22.1122 L12.7541,22.1122 L12.7541,1.2142 L36.1091,1.2142 L36.1091,22.1122 L51.8061,22.1122 L51.7071,41.8232 L36.1091,41.8232 L36.1091,86.1842 C36.1091,90.6022 36.9911,93.6942 38.7591,95.4602 C40.5241,97.2272 43.5061,98.1102 47.7031,98.1102 L52.0531,98.1102 Z"
          fill="#00B1FF"
        />
        <polygon
          fill="#00B1FF"
          points="211.8236 1.2137 183.1686 118.1517 154.3476 118.1517 132.4846 33.3467 110.4546 118.1517 81.7996 118.1517 53.1446 1.2137 78.4876 1.2137 96.3756 91.8157 119.5646 1.2137 145.2386 1.2137 168.5936 91.8157 186.4816 1.2137"
        />
        <polygon fill="#00B1FF" points="219.534 118.152 242.888 118.152 242.888 32.58 219.534 32.58" />
        <path
          d="M220.7747,3.5608 C223.4247,1.1878 226.9027,-0.0002 231.2097,-0.0002 C235.5167,-0.0002 238.9957,1.1878 241.6447,3.5608 C244.2957,5.9358 245.6207,9.0008 245.6207,12.7528 C245.6207,16.3978 244.2957,19.4078 241.6447,21.7808 C238.9957,24.1568 235.5167,25.3418 231.2097,25.3418 C226.9027,25.3418 223.4247,24.1568 220.7747,21.7808 C218.1257,19.4078 216.7997,16.3978 216.7997,12.7528 C216.7997,9.0008 218.1257,5.9358 220.7747,3.5608"
          id="Fill-7"
          fill="#00B1FF"
        />
        <g>
          <path
            d="M272.5169,29.45 L272.5169,35.174 L269.0169,35.174 C266.0839,35.174 263.8379,34.464 262.2769,33.045 C260.7159,31.626 259.9359,29.261 259.9359,25.95 L259.9359,14.598 L256.2929,14.598 L256.2929,8.969 L259.9359,8.969 L259.9359,1.214 L266.6049,1.214 L266.6049,8.969 L272.4699,8.969 L272.4699,14.598 L266.6049,14.598 L266.6049,26.044 C266.6049,27.306 266.8569,28.189 267.3619,28.693 C267.8659,29.198 268.7169,29.45 269.9149,29.45 L272.5169,29.45 Z"
            fill="#FFFFFF"
          />
          <path
            d="M300.14,11.5706 C301.938,13.5566 302.836,16.3476 302.836,19.9426 L302.836,35.1736 L296.214,35.1736 L296.214,20.6996 C296.214,18.6186 295.678,17.0026 294.606,15.8516 C293.534,14.7006 292.068,14.1246 290.206,14.1246 C288.316,14.1246 286.802,14.7396 285.666,15.9696 C284.531,17.1996 283.963,18.9656 283.963,21.2666 L283.963,35.1736 L277.294,35.1736 L277.294,1.2136 L283.963,1.2136 L283.963,14.2196 C284.657,12.4536 285.8,11.0736 287.393,10.0806 C288.985,9.0876 290.822,8.5906 292.904,8.5906 C295.93,8.5906 298.343,9.5846 300.14,11.5706"
            fill="#FFFFFF"
          />
          <path
            d="M324.9476,15.5437 C323.7336,14.4397 322.2436,13.8887 320.4776,13.8887 C318.6806,13.8887 317.1676,14.4397 315.9366,15.5437 C314.7076,16.6477 314.0286,18.2717 313.9026,20.4157 L326.6276,20.4157 C326.7216,18.2717 326.1616,16.6477 324.9476,15.5437 M333.1066,23.3487 L313.9026,23.3487 C313.9986,25.6827 314.6286,27.4007 315.7956,28.5037 C316.9616,29.6077 318.4116,30.1597 320.1466,30.1597 C321.6906,30.1597 322.9766,29.7807 324.0016,29.0237 C325.0266,28.2677 325.6806,27.2437 325.9656,25.9497 L333.0116,25.9497 C332.6656,27.7787 331.9246,29.4187 330.7896,30.8687 C329.6536,32.3197 328.1946,33.4557 326.4146,34.2747 C324.6326,35.0947 322.6536,35.5047 320.4776,35.5047 C317.9246,35.5047 315.6526,34.9607 313.6666,33.8727 C311.6796,32.7847 310.1346,31.2317 309.0316,29.2137 C307.9266,27.1957 307.3756,24.8147 307.3756,22.0707 C307.3756,19.3277 307.9266,16.9397 309.0316,14.9057 C310.1346,12.8717 311.6796,11.3187 313.6666,10.2467 C315.6526,9.1747 317.9246,8.6377 320.4776,8.6377 C323.0626,8.6377 325.3266,9.1747 327.2646,10.2467 C329.2046,11.3187 330.7026,12.7937 331.7596,14.6687 C332.8156,16.5447 333.3436,18.6817 333.3436,21.0777 C333.3436,21.7397 333.2636,22.4967 333.1066,23.3487"
            fill="#FFFFFF"
          />
          <polygon
            fill="#FFFFFF"
            points="302.3167 43.7474 294.1337 77.1414 285.9027 77.1414 279.6597 52.9234 273.3687 77.1414 265.1847 77.1414 257.0027 43.7474 264.2397 43.7474 269.3477 69.6204 275.9697 43.7474 283.3007 43.7474 289.9707 69.6204 295.0797 43.7474"
          />
          <path
            d="M311.3968,58.3396 C310.1038,59.6486 309.4578,61.5486 309.4578,64.0396 C309.4578,66.5306 310.1038,68.4306 311.3968,69.7386 C312.6888,71.0476 314.2658,71.7016 316.1258,71.7016 C317.9878,71.7016 319.5638,71.0476 320.8568,69.7386 C322.1498,68.4306 322.7958,66.5306 322.7958,64.0396 C322.7958,61.5486 322.1498,59.6486 320.8568,58.3396 C319.5638,57.0316 317.9878,56.3766 316.1258,56.3766 C314.2658,56.3766 312.6888,57.0316 311.3968,58.3396 M323.0088,52.2146 C325.0428,53.2866 326.6428,54.8396 327.8098,56.8736 C328.9758,58.9066 329.5598,61.2956 329.5598,64.0396 C329.5598,66.7826 328.9758,69.1636 327.8098,71.1816 C326.6428,73.1996 325.0428,74.7526 323.0088,75.8406 C320.9758,76.9286 318.6818,77.4726 316.1258,77.4726 C313.5728,77.4726 311.2788,76.9286 309.2448,75.8406 C307.2108,74.7526 305.6108,73.1996 304.4448,71.1816 C303.2768,69.1636 302.6938,66.7826 302.6938,64.0396 C302.6938,61.2956 303.2768,58.9066 304.4448,56.8736 C305.6108,54.8396 307.2108,53.2866 309.2448,52.2146 C311.2788,51.1426 313.5728,50.6066 316.1258,50.6066 C318.6818,50.6066 320.9758,51.1426 323.0088,52.2146"
            fill="#FFFFFF"
          />
          <path
            d="M344.8382,52.0013 C346.4462,51.0403 348.2432,50.5583 350.2312,50.5583 L350.2312,57.7013 L348.2912,57.7013 C343.4022,57.7013 340.9592,59.9713 340.9592,64.5123 L340.9592,77.1413 L334.2902,77.1413 L334.2902,50.9373 L340.9592,50.9373 L340.9592,55.9513 C341.9372,54.2803 343.2312,52.9633 344.8382,52.0013"
            fill="#FFFFFF"
          />
          <polygon
            fill="#FFFFFF"
            points="369.2455 77.1414 360.5895 65.6944 360.5895 77.1414 353.9195 77.1414 353.9195 42.7064 360.5895 42.7064 360.5895 61.8164 369.1975 50.9364 377.0975 50.9364 366.0755 63.9924 377.3815 77.1414"
          />
          <path
            d="M386.7943,58.4578 C385.5323,59.8138 384.9013,61.6738 384.9013,64.0398 C384.9013,66.4038 385.5323,68.2568 386.7943,69.5968 C388.0543,70.9378 389.7273,71.6068 391.8083,71.6068 C393.8253,71.6068 395.4893,70.9208 396.7983,69.5498 C398.1073,68.1778 398.7613,66.3408 398.7613,64.0398 C398.7613,61.7058 398.1073,59.8538 396.7983,58.4818 C395.4893,57.1098 393.8253,56.4238 391.8083,56.4238 C389.7273,56.4238 388.0543,57.1028 386.7943,58.4578 M395.4023,52.1668 C396.9793,53.2078 398.0993,54.6108 398.7613,56.3768 L398.7613,50.9368 L405.3833,50.9368 L405.3833,77.1418 L398.7613,77.1418 L398.7613,71.6548 C398.0993,73.4208 396.9793,74.8318 395.4023,75.8878 C393.8253,76.9448 391.9333,77.4728 389.7273,77.4728 C387.5193,77.4728 385.5323,76.9368 383.7673,75.8638 C382.0003,74.7928 380.6213,73.2388 379.6283,71.2058 C378.6343,69.1708 378.1383,66.7828 378.1383,64.0398 C378.1383,61.2958 378.6343,58.9068 379.6283,56.8738 C380.6213,54.8388 382.0003,53.2868 383.7673,52.2138 C385.5323,51.1428 387.5193,50.6058 389.7273,50.6058 C391.9333,50.6058 393.8253,51.1258 395.4023,52.1668"
            fill="#FFFFFF"
          />
          <path
            d="M420.0218,58.4817 C418.7128,59.8537 418.0598,61.7057 418.0598,64.0397 C418.0598,66.3407 418.7128,68.1777 420.0218,69.5497 C421.3298,70.9207 422.9938,71.6067 425.0118,71.6067 C427.0938,71.6067 428.7648,70.9377 430.0258,69.5967 C431.2878,68.2567 431.9178,66.4037 431.9178,64.0397 C431.9178,61.6737 431.2878,59.8137 430.0258,58.4577 C428.7648,57.1027 427.0938,56.4237 425.0118,56.4237 C422.9938,56.4237 421.3298,57.1097 420.0218,58.4817 M433.0538,52.2137 C434.8198,53.2867 436.1988,54.8387 437.1928,56.8737 C438.1858,58.9067 438.6818,61.2957 438.6818,64.0397 C438.6818,66.7827 438.1858,69.1707 437.1928,71.2057 C436.1988,73.2387 434.8198,74.7927 433.0538,75.8637 C431.2878,76.9367 429.3008,77.4727 427.0938,77.4727 C424.8858,77.4727 423.0018,76.9447 421.4418,75.8877 C419.8798,74.8317 418.7688,73.4207 418.1058,71.6547 L418.1058,77.1417 L411.4368,77.1417 L411.4368,42.7067 L418.1058,42.7067 L418.1058,56.3767 C418.7688,54.6107 419.8798,53.2077 421.4418,52.1667 C423.0018,51.1267 424.8858,50.6067 427.0938,50.6067 C429.3008,50.6067 431.2878,51.1427 433.0538,52.2137"
            fill="#FFFFFF"
          />
          <polygon fill="#FFFFFF" points="456.135 77.141 462.805 77.141 462.805 42.706 456.135 42.706" />
          <path
            d="M494.8958,71.4183 L494.8958,77.1413 L492.0828,77.1413 C489.1508,77.1413 486.9048,76.4323 485.3428,75.0133 C483.7828,73.5943 483.0018,71.2293 483.0018,67.9183 L483.0018,56.5653 L479.3598,56.5653 L479.3598,50.9373 L483.0018,50.9373 L483.0018,43.8903 L489.6708,43.8903 L489.6708,50.9373 L494.8478,50.9373 L494.8478,56.5653 L489.6708,56.5653 L489.6708,68.0123 C489.6708,69.2743 489.9228,70.1573 490.4278,70.6613 C490.9318,71.1663 491.7828,71.4183 492.9818,71.4183 L494.8958,71.4183 Z"
            fill="#FFFFFF"
          />
          <polygon
            fill="#FFFFFF"
            points="504.8539 50.9368 511.9969 68.7218 518.6189 50.9368 525.9499 50.9368 509.9149 89.6288 502.5369 89.6288 508.4489 76.3848 497.4279 50.9368"
          />
          <path
            d="M258.516,118.152 L265.185,118.152 L265.185,91.948 L258.516,91.948 L258.516,118.152 Z M258.895,83.26 C259.652,82.582 260.645,82.243 261.874,82.243 C263.105,82.243 264.098,82.582 264.855,83.26 C265.612,83.939 265.99,84.814 265.99,85.886 C265.99,86.926 265.612,87.785 264.855,88.463 C264.098,89.142 263.105,89.48 261.874,89.48 C260.645,89.48 259.652,89.142 258.895,88.463 C258.138,87.785 257.759,86.926 257.759,85.886 C257.759,84.814 258.138,83.939 258.895,83.26 L258.895,83.26 Z"
            fill="#FFFFFF"
          />
          <path
            d="M468.86,77.141 L475.529,77.141 L475.529,50.936 L468.86,50.936 L468.86,77.141 Z M469.239,42.249 C469.994,41.572 470.988,41.233 472.218,41.233 C473.449,41.233 474.442,41.572 475.198,42.249 C475.954,42.928 476.332,43.803 476.332,44.875 C476.332,45.915 475.954,46.775 475.198,47.452 C474.442,48.131 473.449,48.47 472.218,48.47 C470.988,48.47 469.994,48.131 469.239,47.452 C468.482,46.775 468.103,45.915 468.103,44.875 C468.103,43.803 468.482,42.928 469.239,42.249 L469.239,42.249 Z"
            fill="#FFFFFF"
          />
          <path
            d="M443.411,77.141 L450.08,77.141 L450.08,50.936 L443.411,50.936 L443.411,77.141 Z M443.79,42.249 C444.547,41.572 445.54,41.233 446.77,41.233 C448,41.233 448.993,41.572 449.75,42.249 C450.507,42.928 450.885,43.803 450.885,44.875 C450.885,45.915 450.507,46.775 449.75,47.452 C448.993,48.131 448,48.47 446.77,48.47 C445.54,48.47 444.547,48.131 443.79,47.452 C443.034,46.775 442.655,45.915 442.655,44.875 C442.655,43.803 443.034,42.928 443.79,42.249 L443.79,42.249 Z"
            fill="#FFFFFF"
          />
          <path
            d="M294.0863,94.5491 C295.8843,96.5351 296.7813,99.3261 296.7813,102.9211 L296.7813,118.1521 L290.1603,118.1521 L290.1603,103.6781 C290.1603,101.5971 289.6243,99.9811 288.5523,98.8301 C287.4803,97.6791 286.0143,97.1031 284.1523,97.1031 C282.2613,97.1031 280.7483,97.7181 279.6123,98.9481 C278.4773,100.1781 277.9093,101.9441 277.9093,104.2451 L277.9093,118.1521 L271.2393,118.1521 L271.2393,91.9471 L277.9093,91.9471 L277.9093,97.1981 C278.6033,95.4321 279.7463,94.0521 281.3383,93.0591 C282.9313,92.0661 284.7683,91.5691 286.8493,91.5691 C289.8763,91.5691 292.2893,92.5621 294.0863,94.5491"
            fill="#FFFFFF"
          />
          <path
            d="M309.9779,99.4681 C308.7159,100.8241 308.0849,102.6851 308.0849,105.0501 C308.0849,107.4151 308.7159,109.2681 309.9779,110.6081 C311.2389,111.9481 312.9109,112.6181 314.9919,112.6181 C317.0089,112.6181 318.6729,111.9321 319.9819,110.5601 C321.2909,109.1891 321.9449,107.3521 321.9449,105.0501 C321.9449,102.7171 321.2909,100.8641 319.9819,99.4921 C318.6729,98.1201 317.0089,97.4341 314.9919,97.4341 C312.9109,97.4341 311.2389,98.1131 309.9779,99.4681 M318.5629,93.1771 C320.1239,94.2181 321.2499,95.6211 321.9449,97.3871 L321.9449,83.7171 L328.5669,83.7171 L328.5669,118.1521 L321.9449,118.1521 L321.9449,112.6651 C321.2499,114.4311 320.1239,115.8421 318.5629,116.8981 C317.0019,117.9551 315.1169,118.4831 312.9109,118.4831 C310.7029,118.4831 308.7159,117.9471 306.9509,116.8751 C305.1839,115.8031 303.8049,114.2491 302.8119,112.2161 C301.8179,110.1821 301.3219,107.7931 301.3219,105.0501 C301.3219,102.3061 301.8179,99.9181 302.8119,97.8841 C303.8049,95.8501 305.1839,94.2971 306.9509,93.2251 C308.7159,92.1531 310.7029,91.6161 312.9109,91.6161 C315.1169,91.6161 317.0019,92.1371 318.5629,93.1771"
            fill="#FFFFFF"
          />
          <path
            d="M350.8685,98.5223 C349.6545,97.4183 348.1645,96.8663 346.3985,96.8663 C344.6015,96.8663 343.0885,97.4183 341.8575,98.5223 C340.6285,99.6263 339.9495,101.2503 339.8235,103.3943 L352.5475,103.3943 C352.6425,101.2503 352.0815,99.6263 350.8685,98.5223 M359.0275,106.3273 L339.8235,106.3273 C339.9185,108.6613 340.5495,110.3793 341.7165,111.4823 C342.8825,112.5863 344.3325,113.1383 346.0675,113.1383 C347.6115,113.1383 348.8975,112.7593 349.9225,112.0023 C350.9475,111.2463 351.6015,110.2213 351.8865,108.9283 L358.9325,108.9283 C358.5855,110.7573 357.8455,112.3973 356.7105,113.8473 C355.5745,115.2983 354.1155,116.4333 352.3355,117.2533 C350.5525,118.0733 348.5745,118.4833 346.3985,118.4833 C343.8455,118.4833 341.5735,117.9393 339.5875,116.8513 C337.6005,115.7633 336.0555,114.2103 334.9515,112.1923 C333.8475,110.1743 333.2965,107.7933 333.2965,105.0493 C333.2965,102.3063 333.8475,99.9183 334.9515,97.8843 C336.0555,95.8503 337.6005,94.2973 339.5875,93.2253 C341.5735,92.1533 343.8455,91.6163 346.3985,91.6163 C348.9835,91.6163 351.2465,92.1533 353.1855,93.2253 C355.1255,94.2973 356.6235,95.7723 357.6805,97.6473 C358.7355,99.5233 359.2645,101.6603 359.2645,104.0563 C359.2645,104.7183 359.1845,105.4753 359.0275,106.3273"
            fill="#FFFFFF"
          />
          <polygon
            fill="#FFFFFF"
            points="378.5169 118.1517 372.5569 109.7327 367.5429 118.1517 360.2109 118.1517 368.8679 104.4827 360.0219 91.9477 367.4959 91.9477 373.3139 100.2247 378.3279 91.9477 385.6589 91.9477 376.8609 105.2387 385.9909 118.1517"
          />
        </g>
      </svg>
    </StyledHeader.Logo>
  );
};

export default HeaderLogo;
