// Import Dependencies
import styled from 'styled-components';
import getModifier from '../../../utils/getModifier';

import * as vars from '../../global/vars';

const Button = styled.button`
  border: 2px solid ${vars.colors.grey300};
  display: none;
  font-size: ${vars.fontSize.small};
  margin-left: -2px;
  min-width: 130px;
  padding: ${vars.spacing.xSmall} ${vars.spacing.base};
  position: relative;
  transition: none;
  z-index: 0;

  ${vars.breakpoint.medium`
    display: inline-block;
  `};

  &:first-child {
    margin-left: 0;
  }

  ${props =>
    getModifier(props, 'isSelected') &&
    `
      background-color: ${vars.colors.blue100};
      border-color: ${vars.colors.blue500};
      font-weight: ${vars.fontWeight.semibold};
      z-index: 1;

      &:first-child {
        border-color: ${vars.colors.blue500};
      }
  `};
`;

export default Button;
