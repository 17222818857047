// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';

const Icon = styled.div`
  position: absolute;
  height: 100%;
  width: 46px;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.colors.blue500};

  pointer-events: none;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export default Icon;
