// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

import getModifier from '../../../utils/getModifier';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${vars.components.cardHeightMobile};
  height: 100%;

  ${vars.breakpoint.medium`
    min-height: ${vars.components.cardHeight};
  `};

  ${props =>
    getModifier(props, 'reverseVideo') &&
    `
      flex-direction: column-reverse;
    `};

  ${props =>
    getModifier(props, 'sliderCard') &&
    `
      height: auto;
    `};

  ${props =>
      getModifier(props, 'border') &&
      `
        border: 2px solid ${vars.colors.grey300};
    `};
`;

export default Wrapper;
