// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';
import StyledFooter from '../../styles/components/FooterMarketing';

// Import Utilities
import { generateLink } from '../../utils';

const NavItemMarketing = ({
  id,
  linkText,
  toggleExternalUrl,
  linkExternalUrl,
  linkInternalEntry,
  hideOnMobile,
  openInNewWindow,
  footerNavItem,
  currentPath,
}) => {
  const linkObject = generateLink(linkInternalEntry, toggleExternalUrl, linkExternalUrl, openInNewWindow);

  if (footerNavItem) {
    return (
      <StyledFooter.Item key={id}>
        <a {...linkObject}>{linkText}</a>
      </StyledFooter.Item>
    );
  }

  return (
    <StyledHeader.Item
      key={id}
      modifiers={{
        isActive: linkInternalEntry && linkInternalEntry[0] && linkInternalEntry[0].uri === currentPath,
        hideOnMobile,
      }}
    >
      <a {...linkObject}>{linkText}</a>
    </StyledHeader.Item>
  );
};

NavItemMarketing.propTypes = {
  currentPath: PropTypes.string,
  linkExternalUrl: PropTypes.string,
  linkInternalEntry: PropTypes.arrayOf(PropTypes.shape({})),
  toggleExternalUrl: PropTypes.bool.isRequired,
  footerNavItem: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  id: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

NavItemMarketing.defaultProps = {
  currentPath: null,
  linkExternalUrl: null,
  linkInternalEntry: null,
  footerNavItem: false,
  hideOnMobile: false,
  openInNewWindow: false,
};

export default NavItemMarketing;
