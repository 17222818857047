import * as vars from './vars';

const typography = {
  heading1: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.xxLarge};
    font-weight: ${vars.fontWeight.bold};
    letter-spacing: -0.6px;
    line-height: 1.13;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.xxxxLarge};
    };
  `,
  heading2: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.medium};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.2;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.xxxLarge};
    };
  `,
  heading3: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.base};
    font-weight: ${vars.fontWeight.normal};
    line-height: 1.3;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.xxLarge};
    };
  `,
  heading4: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.base};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.4;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.large};
    };
  `,
  heading5: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.base};
    font-weight: ${vars.fontWeight.normal};
    line-height: 1.33;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.medium};
    };
  `,

  heading6: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.xSmall};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.5;
    text-transform: uppercase;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.small};
    }
  `,
  heading7: `
    color: ${vars.colors.grey400};
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.xSmall};
    font-weight: ${vars.fontWeight.semibold};
    line-height: 1.33;
    opacity: 0.3;
    text-transform: uppercase;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.xSmall};
    };
  `,
  body: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.normal};
    line-height: 1.38;

    @media (min-width: ${vars.bp.medium}) {
      font-size: ${vars.fontSize.base};
    };
  `,
  smallText: `
  font-family: ${vars.baseFontFamily};
  font-size: ${vars.fontSize.xSmall};
  font-weight: ${vars.fontWeight.normal};
  line-height: 1.38;

  @media (min-width: ${vars.bp.medium}) {
    font-size: ${vars.fontSize.small};
  };
`,
  smallCaps: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.xSmall};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.5;
    text-transform: uppercase;
  `,
  label: `
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.43;
  `,
  textLink: `
    color: ${vars.colors.blue500};
    display: inline;
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.bold};
    line-height: 1.43;
  `,
  alert: `
    color: ${vars.colors.red500};
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.semibold};
  `,
  success: `
    color: ${vars.colors.green500};
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.semibold};
  `,
};

export default typography;
