// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

const Label = styled.label`
  position: relative;
  padding-left: 40px;
  transition: all ${vars.global.transitionSpeed} ${vars.global.easing};

  &:before,
  &:after {
    position: absolute;
    transition: all ${vars.global.transitionSpeed} ${vars.global.easing};
  }

  &:before {
    content: '';
    display: inline-block;

    height: 26px;
    width: 26px;

    border: ${vars.colors.blue200} solid 2px;
    background-color: white;

    left: 0;
  }

  &:hover {
    &:before {
      background-color: ${vars.colors.blue200};
    }
  }

  &:after {
    content: '';
    display: inline-block;
    height: 7px;
    width: 11px;
    border-left: 2px solid ${vars.colors.blue500};
    border-bottom: 2px solid ${vars.colors.blue500};

    transform: rotate(-45deg);

    left: 8px;
    top: 6px;
  }
`;

export default Label;
