// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';
import ImageLoader from '../ImageLoader';
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import HighlightsOffset from '../../styles/components/HighlightsOffset';
import Section from '../../styles/objects/Section';
import Wysiwyg from '../../styles/components/Wysiwyg';

// Import Utilities
import { generateLink } from '../../utils';
import cleanHtml from '../../utils/cleanHtml';

const HighlightsBlock = ({ highlightHeadline, highlight, image, storybook, noBottomPadding }) => {
  const { headlineHeading: heading, ctaText, ctaSwitch, ctaExternallink, ctaInternalEntry } =
    (highlightHeadline && highlightHeadline[0]) || {};

  const imageUrl = image && image[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${image[0].url}`;

  return (
    <Section modifiers={{ dotsLeft: imageUrl && !heading, noBottomPadding }}>
      <Container>
        {imageUrl && !heading && (
          <Section.Image modifiers={{ highlights: true }}>
            <ImageLoader modifiers={{ noAspectRatio: true }} imageUrl={imageUrl} />
          </Section.Image>
        )}
        <Grid>
          <Grid.Item modifiers={{ colsMedium: 3 }}>
            <Grid.Inner>
              {heading && (
                <HighlightsOffset modifiers={{ useMargin: highlight && highlight.length < 3 }}>
                  <HighlightsOffset.Title>{heading}</HighlightsOffset.Title>
                  {ctaText && (
                    <Button {...generateLink(ctaInternalEntry, ctaSwitch, ctaExternallink)}>{ctaText}</Button>
                  )}
                </HighlightsOffset>
              )}
            </Grid.Inner>
          </Grid.Item>

          {highlight &&
            highlight.map((highlightItem, i) => {
              const { id, highlightHeading, description } = highlightItem;

              return (
                <Grid.Item key={id} modifiers={{ colsSmall: 6, colsMedium: 3, offsetMedium: i && i % 2 === 0 ? 4 : 1 }}>
                  <Grid.Inner modifiers={{ largeBottomMargin: true }}>
                    <Title type="p" styleType="h4" modifiers={{ darkGrey: true, smallMarginBottom: true }}>
                      {`${i + 1}`.padStart(2, '0')}
                    </Title>
                    <Wysiwyg>
                      <h4>{highlightHeading}</h4>
                      {description && description.content && (
                        <Wysiwyg dangerouslySetInnerHTML={{ __html: cleanHtml(description.content) }} />
                      )}
                    </Wysiwyg>
                  </Grid.Inner>
                </Grid.Item>
              );
            })}
        </Grid>
      </Container>
    </Section>
  );
};

HighlightsBlock.propTypes = {
  highlightHeadline: PropTypes.arrayOf(PropTypes.shape({})),
  highlight: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  storybook: PropTypes.bool,
  noBottomPadding: PropTypes.bool,
  image: PropTypes.shape({}),
};

HighlightsBlock.defaultProps = {
  highlightHeadline: null,
  storybook: false,
  image: null,
  noBottomPadding: false,
};

export default HighlightsBlock;
