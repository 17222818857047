// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Card from '../Card';

const CmsCard = ({ i, toggleType, youtubeId, image, videoImage, cardContent, storybook, ...props }) => {
  const content = cardContent && cardContent.content;

  if (toggleType === 'video') {
    const videoImageUrl =
      videoImage && videoImage[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${videoImage[0].url}`;

    return (
      <Card
        modifiers={{ noBorder: true }}
        noBorder
        {...props}
        content={content}
        videoImage={videoImageUrl}
        video={youtubeId}
      />
    );
  }

  if (toggleType === 'image') {
    const imageUrl = image && image[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${image[0].url}`;

    return <Card {...props} content={content} imageUrl={imageUrl} />;
  }

  if (toggleType === 'dimension') {
    return <Card {...props} content={content} Header={`${i + 1}`.padStart(2, '0')} />;
  }

  return <Card {...props} content={content} />;
};

CmsCard.propTypes = {
  cardContent: PropTypes.shape({}),
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  i: PropTypes.number.isRequired,
  image: PropTypes.arrayOf(PropTypes.shape({})),
  storybook: PropTypes.bool,
  toggleType: PropTypes.string.isRequired,
  videoImage: PropTypes.arrayOf(PropTypes.shape({})),
  youtubeId: PropTypes.string,
};

CmsCard.defaultProps = {
  cards: [],
  youtubeId: null,
  image: null,
  videoImage: null,
  cardContent: null,
  storybook: false,
};

export default CmsCard;
