// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

// Import elements
import HeaderLogo from './HeaderLogo';
import NavItemMarketing from './NavItemMarketing';
import NavMarketing from './NavMarketing';
import ActionsDesktop from './ActionsDesktop';
import ActionsMobile from './ActionsMobile';
import { NavMobileMenuButton, NavMobileMenuButtonMiddleLine } from './NavMobileMenuButton';

import getModifier from '../../../utils/getModifier';

const HeaderMarketing = styled.header`
  align-items: center;
  background-color: ${vars.colors.grey500};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${vars.components.headerHeightMobile};
  justify-content: space-between;
  left: 0;
  margin: 0;
  padding: 0 ${vars.spacing.base};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${vars.layer.nav};

  ${vars.breakpoint.medium`
    height: ${vars.components.headerHeightDesktop};
    padding: 0 0 0 ${vars.spacing.base};
  `};

  ${props =>
    getModifier(props, 'fixed') &&
    vars.breakpoint.medium`
      position: fixed;
      top: 0;
      left: 0;
  `};
`;

// Set elements
HeaderMarketing.Logo = HeaderLogo;
HeaderMarketing.Item = NavItemMarketing;
HeaderMarketing.Nav = NavMarketing;
HeaderMarketing.ActionsDesktop = ActionsDesktop;
HeaderMarketing.ActionsMobile = ActionsMobile;
HeaderMarketing.NavMobileMenuButton = NavMobileMenuButton;
HeaderMarketing.NavMobileMenuButtonMiddleLine = NavMobileMenuButtonMiddleLine;

export default HeaderMarketing;
