// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

// Import utilities
import { visuallyHidden } from '../../global/utils';

const Button = styled.button`
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  svg {
    display: block;
    fill: ${vars.colors.blue500};
    height: 90px;
    transition: transform ${vars.global.transitionSpeed} ${vars.global.easing};
    width: 90px;
  }

  span {
    ${visuallyHidden}
  }
`;

export default Button;
