// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import components
import Video from '../Video';
import Link from '../Link';
import ImageLoader from '../ImageLoader';

// Import Styled Components
import StyledCard from '../../styles/components/Card';
import Wysiwyg from '../../styles/components/Wysiwyg';

// Import Utilities
import { generateLink } from '../../utils';
import cleanHtml from '../../utils/cleanHtml';

const Card = ({
  title,
  modifiers,
  content,
  noBorder,
  dark,
  video,
  videoImage,
  fullWidth,
  sliderCard,
  imageUrl,
  reverseVideo,
  linkText,
  linkSwitch,
  linkExternalUrl,
  linkInternalEntry,
  Header,
  cardModifiers,
  cardWrapperModifiers,
}) => {
  return (
    <StyledCard.Wrapper modifiers={{ video, reverseVideo, sliderCard, ...cardWrapperModifiers }}>
      {video && <Video videoCode={video} poster={videoImage} />}
      {imageUrl && <ImageLoader imageUrl={imageUrl} />}
      <StyledCard
        modifiers={{ dark, noBorder, video, sliderCard, fullWidth, image: !!imageUrl, ...modifiers, ...cardModifiers }}
      >
        {Header && <StyledCard.Header modifiers={{ dark }}>{Header}</StyledCard.Header>}
        {title && (
          <StyledCard.Title modifiers={{ dark, fontSemiBold: true }} type="h3">
            {title}
          </StyledCard.Title>
        )}
        {content && (
          <Wysiwyg modifiers={{ compact: true, dark }} dangerouslySetInnerHTML={{ __html: cleanHtml(content) }} />
        )}
        {linkText && (
          <StyledCard.Actions>
            <Link {...generateLink(linkInternalEntry, linkSwitch, linkExternalUrl)}>{linkText}</Link>
          </StyledCard.Actions>
        )}
      </StyledCard>
    </StyledCard.Wrapper>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  content: PropTypes.node,
  dark: PropTypes.bool,
  Header: PropTypes.string,
  modifiers: PropTypes.shape({}),
  noBorder: PropTypes.bool,
  video: PropTypes.shape({}),
  cardModifiers: PropTypes.shape({}),
  cardWrapperModifiers: PropTypes.shape({}),
  videoImage: PropTypes.string,
  reverseVideo: PropTypes.bool,
  linkText: PropTypes.string,
  linkSwitch: PropTypes.bool,
  linkExternalUrl: PropTypes.string,
  linkInternalEntry: PropTypes.string,
  fullWidth: PropTypes.bool,
  sliderCard: PropTypes.bool,
};

Card.defaultProps = {
  title: null,
  content: null,
  dark: false,
  Header: null,
  noBorder: false,
  video: null,
  imageUrl: null,
  modifiers: {},
  reverseVideo: false,
  videoImage: null,
  linkText: null,
  linkSwitch: false,
  fullWidth: false,
  sliderCard: false,
  linkExternalUrl: null,
  cardModifiers: {},
  cardWrapperModifiers: {},
  linkInternalEntry: null,
};

export default Card;
