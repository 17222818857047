// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from './vars';

export const HideOnMobile = styled.div`
  display: none;
  width: 100%;

  ${vars.breakpoint.medium`
    display: block;
  `};
`;

export const HideOnDesktop = styled.div`
  display: block;
  width: 100%;

  ${vars.breakpoint.medium`
    display: none;
  `};
`;
