// Import dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Import Global Styles
import GlobalStyle from '../../styles/base';

// Import favicons
import favicon16 from '../../static/favicons/favicon-16x16.png';
import favicon32 from '../../static/favicons/favicon-32x32.png';
import favicon96 from '../../static/favicons/favicon-96x96.png';
import favicon from '../../static/favicons/favicon.ico';

// Import Components
import HeaderMarketing from '../HeaderMarketing';
import Hero from '../Hero';
import FooterMarketing from '../FooterMarketing';

// Import Styled Components
import PageMain from '../../styles/objects/PageMain';

class LayoutMarketing extends PureComponent {
  state = {
    isLoaded: false,
  };

  componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    const { children, navItems, hero, footerLinks, loginUrl, getStartedUrl, title, '*': uri } = this.props;
    const { isLoaded } = this.state;

    if (!isLoaded) return false;

    return (
      <PageMain modifiers={{ withFixedHeader: true }}>
        <GlobalStyle />
        <Helmet
          title={title}
          defer={false}
          link={[
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
            { rel: 'icon', type: 'image/png', sizes: '96x96', href: `${favicon96}` },
            { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
          ]}
        />
        <HeaderMarketing
          fixed
          navItems={navItems}
          loginUrl={loginUrl}
          getStartedUrl={getStartedUrl}
          currentPath={uri}
        />
        {hero && <Hero {...hero} />}
        {children}
        <FooterMarketing navItems={footerLinks} />
      </PageMain>
    );
  }
}

LayoutMarketing.propTypes = {
  children: PropTypes.node.isRequired,
  footerLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getStartedUrl: PropTypes.string.isRequired,
  hero: PropTypes.shape({}),
  loginUrl: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  '*': PropTypes.string.isRequired,
};

LayoutMarketing.defaultProps = {
  hero: null,
  title: 'The Workability Index',
};

export default LayoutMarketing;
