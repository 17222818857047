// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Down = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g fill="currentColor">
      <path d="M250 361.2c-9.8 0-19.2-4-26-11.1L80 199.7c-13.7-14.3-13.3-37.1 1.1-50.9 14.4-13.7 37.2-13.2 50.9 1.1l118 123.2 118-123.2c13.8-14.3 36.6-14.8 50.9-1.1 14.3 13.8 14.8 36.6 1.1 50.9L276 350.1c-6.8 7.1-16.2 11.1-26 11.1z" />
    </g>
  </Icon>
);

export default Down;
