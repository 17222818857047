// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Price from './Price';
import Title from './Title';
import Wysiwyg from './Wysiwyg';
import Col from './Col';
import TextBig from './TextBig';

// Import Globals
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';

const PlanCard = styled.div`
  background-color: ${vars.colors.white};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  margin: 0 auto;
  max-width: ${toRems('820px')};
  padding: ${vars.spacing.medium} ${vars.spacing.base};
  width: 100%;

  ${vars.breakpoint.medium`
    padding: ${vars.spacing.large};
  `};
`;

PlanCard.Price = Price;
PlanCard.Title = Title;
PlanCard.Wysiwyg = Wysiwyg;
PlanCard.Col = Col;
PlanCard.TextBig = TextBig;

export default PlanCard;
