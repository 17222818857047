// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Play = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <circle cx="250" cy="250" r="180" fill="#fff" />
    <path d="M284.9 255l-49.3 24.6c-2.8 1.4-6.2.3-7.5-2.5-.4-.8-.6-1.6-.6-2.5v-49.3c0-3.1 2.5-5.6 5.6-5.6.9 0 1.7.2 2.5.6l49.3 24.6c2.8 1.4 3.9 4.8 2.5 7.5-.5 1.2-1.4 2.1-2.5 2.6z" />
  </Icon>
);

export default Play;
