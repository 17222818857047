// Import Dependencies
import styled from 'styled-components';

// Import utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';

const Icon = styled.svg`
  display: inline-block;
  height: 100%;
  width: 100%;

  /* Modifier: storybook */
  ${props =>
    getModifier(props, 'storybook') &&
    `
    g {
      fill: ${vars.colors.grey500};
    }
  `};

  /* Modifier: storybook */
  ${props =>
    getModifier(props, 'expanded') &&
    `
      transform: rotate(180deg);
  `};
`;

export default Icon;
