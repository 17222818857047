import * as vars from '../global/vars';

const nprogress = `
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background-color: ${vars.colors.blue500};
    height: 5px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${vars.layer.progress};
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
`;

export default nprogress;
