// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import NavItemMarketing from '../NavItemMarketing';
import Button from '../Button';
import * as Icon from '../Icons';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';
import StyledFooter from '../../styles/components/FooterMarketing';

const NavMarketing = ({ navItems, modifiers, getStartedUrl, loginUrl, footerNav, ...props }) =>
  footerNav ? (
    <StyledFooter.Nav modifiers={modifiers} {...props}>
      <ul>
        {navItems.map(item => (
          <NavItemMarketing footerNavItem key={item.id} {...item} {...props} />
        ))}
      </ul>
    </StyledFooter.Nav>
  ) : (
    <StyledHeader.Nav modifiers={modifiers} {...props}>
      <ul>
        {navItems.map(item => (
          <NavItemMarketing key={item.id} {...props} {...item} />
        ))}
        <NavItemMarketing toggleExternalUrl linkExternalUrl={loginUrl} linkText="Login" hideOnMobile />
      </ul>

      <StyledHeader.ActionsDesktop>
        <Button href={getStartedUrl} modifiers={{ smallWidth: true, headerButtonOnDesktop: true }}>
          Get started
        </Button>
      </StyledHeader.ActionsDesktop>

      <StyledHeader.ActionsMobile>
        <Button href={getStartedUrl} modifiers={{ hasIcon: true, largeWidth: true, hideOnDesktop: true }}>
          <Icon.Dashboard />
          {' '}
Get Started
        </Button>

        <Button href={loginUrl} modifiers={{ secondary: true, hasIcon: true, largeWidth: true, hideOnDesktop: true }}>
          <Icon.LoginLogout />
          {' '}
Login
        </Button>
      </StyledHeader.ActionsMobile>
    </StyledHeader.Nav>
  );

NavMarketing.propTypes = {
  footerNav: PropTypes.bool,
  getStartedUrl: PropTypes.string,
  loginUrl: PropTypes.string,
  modifiers: PropTypes.shape({}),
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
};

NavMarketing.defaultProps = {
  footerNav: false,
  getStartedUrl: null,
  loginUrl: null,
  modifiers: {},
  navItems: [],
};

export default NavMarketing;
