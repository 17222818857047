import * as vars from '../global/vars';
import typography from '../global/typography';

export default `
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    color: ${vars.colors.white};
    background: ${vars.colors.black};
  }

  ::-moz-selection {
    color: ${vars.colors.white};
    background: ${vars.colors.black};
  }

  html,
  body {
    -moz-osx-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased !important;
    background-color: ${vars.colors.grey500}; 
    color: ${vars.baseFontColor};
    font-family: ${vars.baseFontFamily};
    font-size: ${vars.baseFontSize};
    font-weight: normal;
    height: 100%;
    line-height: 1.6;
    margin: 0;
    min-height: 100%;
    min-width: 320px;
    position: relative;
    text-rendering: optimizeLegibility !important;
    width: 100%;
    z-index: 0;
  }

  a[href*="mailto:"] {
    word-wrap: break-word;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: ${vars.baseFontSize};
    outline: none;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${vars.colors.grey500};
    margin-bottom: ${vars.spacing.small};
    margin-top: 0;

    & > span {
      font-weight: 400;
    }
  }

  h1 {
    ${typography.headingXLarge}
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    ${typography.headingBase}
  }

  {/* Reset Lists */}
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  {/* Images */}
  figure {
    margin: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${vars.colors.black};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    ${typography.body};
    margin-top: 0;
    margin-bottom: ${vars.spacing.small};

    b,
    strong {
      font-weight: bold;
    }

    i,
    em {
      font-style: italic;
    }
  }

  svg {
    display: inline-block;
  }

  {/* FORMS */}
  form {

    fieldset {
      border: none;
      padding: 0;
    }

    legend {

    }

    ol {
      @include clearfix;

      li {
        margin-bottom: $spacing-base;
      }
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  textarea {
    resize: none;
    min-height: 120px;
  }


  {/* Default Transitions */}
  a,
  input,
  select,
  textarea,
  button,
  [class^="c-btn"] {
    -webkit-tap-highlight-color: ${vars.global.tapHighlightColor};
    transition-duration: 0.5s;
    transition-property: background-color, color, opacity, border, border-left, border-right, border-color, transform;
    transition-timing-function: ${vars.global.easing};
    transform-origin: center center;
  }

  .StripeElement{
    border: ${vars.colors.blue200} solid 2px;
    color: ${vars.colors.grey500};
    padding:12px;
  }

  .StripeElement--focus{
    border-color: ${vars.colors.blue500};
    background-color: ${vars.colors.blue200};
  }
`;
