// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

const NavMarketing = styled.div`
  align-items: flex-start;
  background-color: ${vars.colors.grey500};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 ${vars.spacing.large};

  ${vars.breakpoint.medium`
    flex-direction: row;
    padding: 0 ${vars.spacing.large} 0 0;
  `};

  ul {
    align-items: flex-start;
    background-color: ${vars.colors.grey500};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${vars.breakpoint.medium`
      align-items: center;
      flex-direction: row;
    `}

    &:hover {
      li {
        opacity: 0.3;

        &:visited {
          opacity: 0.3;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

export default NavMarketing;
