// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import typography from '../../global/typography';
import { hexToRgba } from '../../global/utils';

const Caption = styled.p`
  ${typography.smallText}
  color: ${hexToRgba(vars.colors.white, 0.5)};
  margin: ${vars.spacing.xSmall} 0 0;

  ${vars.breakpoint.medium`
    margin: ${vars.spacing.small} 0 0;
  `};

`;

export default Caption;
