// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${vars.spacing.base};

  ${props => getModifier(props, 'largeSpacingTop') && `margin-top: ${vars.spacing.large}`};

  ${props =>
    getModifier(props, 'borderTop') &&
    `
      border-top: 1px solid ${vars.colors.grey300};
      padding-top: ${vars.spacing.large};
    `};
`;

export default Actions;
