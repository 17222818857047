// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';
import getModifier from '../../../utils/getModifier';

const AccordionSectionTitle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;

  svg {
    display: block;
    fill: ${vars.colors.white};
    flex: 0 0 auto;
    height: ${toRems('15px')};
    margin: ${vars.spacing.xxSmall} 0 0 ${vars.spacing.midLarge};
    width: ${toRems('15px')};
  }

  ${props =>
    getModifier(props, 'darkIcons') &&
    `
    svg {
    fill: ${vars.colors.grey500};
    }
  `}
`;

export default AccordionSectionTitle;
