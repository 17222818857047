// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

import getModifier from '../../../utils/getModifier';

const PageMain = styled.div`
  background-color: ${vars.colors.white};
  overflow: hidden;
  width: 100%;

  ${props =>
    getModifier(props, 'withFixedHeader') &&
    vars.breakpoint.medium`
      margin-top: ${vars.components.headerHeightDesktop};
    `};
`;

export default PageMain;
