// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Label from './Label';
import Optional from './Optional';

// Import Globals
import * as vars from '../../global/vars';
import forms from '../../global/forms';
import typography from '../../global/typography';
import { hexToRgba } from '../../global/utils';

const TextInput = styled.div`
  border: ${vars.colors.blue200} solid 2px;
  color: ${vars.colors.grey500};
  max-width: 100%;
  position: relative;
  /* z-index: 100; */

  &:focus-within {
    border-color: ${vars.colors.blue500};
    background-color: ${vars.colors.blue200};
  }

  /* ${({ hasFocus }) =>
    hasFocus &&
    `
    border-color: ${vars.colors.blue500};
    background-color: ${vars.colors.blue200};
  `} */

  ${({ hasError }) =>
    hasError &&
    `
    border-color: ${vars.colors.red500};
  `}

  &:hover {
    background-color: ${vars.colors.blue200};
  }

  transition: all ${vars.global.transitionSpeed} ${vars.global.easing};

  input,
  select,
  textarea {
    ${forms.fieldReset};
    ${typography.body};
    background-color: ${vars.colors.white};
    font-size: ${vars.fontSize.base};
    padding: ${vars.fontSize.xSmall};

    &::placeholder {
      color: ${hexToRgba(vars.colors.grey500, 0.5)};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 1;
    }
  }

  select {
    padding-right: ${vars.spacing.large}; /* space for chevron */
  }
`;

TextInput.Label = Label;
TextInput.Optional = Optional;

export default TextInput;
