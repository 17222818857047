// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import AccordionSection from '../AccordionSection';
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Section from '../../styles/objects/Section';
import StyledAccordion from '../../styles/components/Accordion';

const Accordion = ({ accordion, heading, modifiers, sectionModifiers, titleModifiers, ...props }) => {
  return (
    <Section modifiers={{ dark: true, center: true, ...sectionModifiers }}>
      <Container>
        {heading && (
          <Title type="h3" styleType="h2" modifiers={{ white: true, xLargeMargin: true, ...titleModifiers }}>
            {heading}
          </Title>
        )}
        <StyledAccordion modifiers={{ ...modifiers }}>
          {accordion.map(item => (
            <AccordionSection key={item.id} {...item} {...props} modifiers={{ ...modifiers }} />
          ))}
        </StyledAccordion>
      </Container>
    </Section>
  );
};

Accordion.propTypes = {
  accordion: PropTypes.arrayOf(PropTypes.shape({})),
  modifiers: PropTypes.shape({}),
  sectionModifiers: PropTypes.shape({}),
  titleModifiers: PropTypes.shape({}),
  heading: PropTypes.string,
};

Accordion.defaultProps = {
  accordion: [],
  modifiers: {},
  sectionModifiers: {},
  titleModifiers: {},
  heading: null,
};

export default Accordion;
