// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';

const Wrapper = styled.div`
  margin: ${vars.spacing.small} 0;
`;

export default Wrapper;
