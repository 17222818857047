// Import Dependencies
import styled from 'styled-components';

// Import Vars
import * as vars from '../../global/vars';

const PlanSelectorLabel = styled.div`
  font-size: ${vars.fontSize.small};
  font-weight: ${vars.fontWeight.semibold};
  margin-bottom: ${vars.spacing.xxSmall};
  text-align: left;
  width: 100%;

  ${vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.small};
    text-align: center;
  `};
`;

export default PlanSelectorLabel;
