// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';
import getModifier from '../../../utils/getModifier';

const HeaderLogo = styled.span`
  svg {
    display: block;
    height: ${toRems('20px')};
    width: ${toRems('90px')};

    ${vars.breakpoint.medium`
      height: ${toRems('32px')};
      width: ${toRems('145px')};
    `};
  }

  ${props =>
    getModifier(props, 'application') &&
    `
    svg {
      height: ${toRems('26px')};
      width: ${toRems('110px')};
    }
  `}

  ${props =>
    getModifier(props, 'application') &&
    vars.breakpoint.medium`
      display: block;
      height: ${toRems('48px')};
      margin-bottom: ${vars.spacing.base};
      overflow: hidden;
      width: ${toRems('48px')};

      svg {
        height: ${toRems('25px')};
        width: ${toRems('100px')};

        g {
          display: none;
        }
      }
  `}

  ${props =>
    getModifier(props, 'application') &&
    vars.breakpoint.large`
      height: auto;
      width: auto;

      svg {
        height: ${toRems('32px')};
        width: ${toRems('145px')};

        g {
          display: block;
        }
      }
  `}
`;

export default HeaderLogo;
