// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

const Divider = styled.div`
  border-bottom: solid 1px ${vars.colors.grey300};
  height: 0;
  margin: ${vars.spacing.base} 0;

  @media print {
    display: none;
  }

  ${vars.breakpoint.medium`
    margin: ${vars.spacing.large} 0;
  `}
`;

export default Divider;
