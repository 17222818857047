// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';
import CmsCard from '../CmsCard';

// Import Styled Components
import ButtonList from '../../styles/components/ButtonList';
import Container from '../../styles/objects/Container';
import Section from '../../styles/objects/Section';
import Grid from '../../styles/objects/Grid';

// Import Vars
import * as vars from '../../styles/global/vars';

const cardIncrement = 6;

class CardGrid extends PureComponent {
  state = { cardLimit: 9 };

  componentDidMount() {
    this.setState({
      cardLimit: window && window.matchMedia(`(min-width: ${vars.bp.medium})`).matches ? 9 : 6,
    });
  }

  handleLoadMore = () => {
    this.setState(prevState => ({
      cardLimit: prevState.cardLimit + cardIncrement,
    }));
  };

  render() {
    const { cards, storybook, modifiers, ...props } = this.props;

    const { cardLimit } = this.state;

    const limitCards = cards.length > cardLimit;
    const limitedCards = limitCards ? cards.slice(0, cardLimit) : cards;

    return (
      <Section
        modifiers={{
          dark: !storybook,
          dots: true,
          noTopPaddingIfFirst: true,
          dotsWhite: true,
          dotsOuter: true,
          ...modifiers,
        }}
      >
        <Container>
          <Grid>
            {limitedCards.map((card, i) => (
              <Grid.Item id={card.id} i={i} modifiers={{ colsSmall: 6, colsMedium: 4 }}>
                <Grid.Inner>
                  <CmsCard i={i} storybook={storybook} {...card} {...props} />
                </Grid.Inner>
              </Grid.Item>
            ))}
          </Grid>

          {limitCards && (
            <ButtonList>
              <li>
                <Button onClick={this.handleLoadMore}>Load more</Button>
              </li>
            </ButtonList>
          )}
        </Container>
      </Section>
    );
  }
}

CardGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  storybook: PropTypes.bool,
  modifiers: PropTypes.shape({}),
};

CardGrid.defaultProps = {
  cards: [],
  storybook: false,
  modifiers: {},
};

export default CardGrid;
