// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import * as utilities from '../../utilities/accessibility';
import { toRems } from '../../global/utils';

// Import Utilities
import getModifier from '../../../utils/getModifier';

export const NavMobileMenuButton = styled.button`
  cursor: pointer;
  height: ${toRems('14px')};
  overflow: hidden;
  position: relative;
  width: ${toRems('24px')};

  ${vars.breakpoint.medium`
    display: none;
  `};

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    background-color: ${vars.colors.white};
    content: '';
    display: block;
    height: ${toRems('2px')};
    position: absolute;
    top: 0;
    transition: transform ${vars.global.transitionSpeed} ${vars.global.easing};
    width: ${toRems('24px')};
  }

  &:before {
    right: 0;
    transform: translateY(0);
    width: ${toRems('13px')};
  }

  &:after {
    left: 0;
    transform: translateY(12px);
  }

  span {
    ${utilities.visuallyHidden}
  }

  /* Modifier: isOpen */
  ${props =>
    getModifier(props, 'isOpen') &&
    `
      &:before {
        width: ${toRems('14px')};
        transform: translate(-4px, 4px) rotate(45deg);
      }

      &:after {
        width: ${toRems('14px')};
        transform: translate(6px, 4px) rotate(-45deg);
      }

      div {
        opacity: 0;
      }
  `};
`;

export const NavMobileMenuButtonMiddleLine = styled.div`
  background-color: ${vars.colors.white};
  height: ${toRems('2px')};
  margin: ${toRems('3px')} 0;
  opacity: 1;
  transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing};
  width: ${toRems('24px')};
`;
