// Import Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement } from 'react-stripe-elements';

// Import Components
import DateField from '../DateField';
import Legend from '../Legend';
import Text from '../Text';
import TextArea from '../TextArea';
import Title from '../Title';
import TextField from '../TextField';
import SelectField from '../SelectField';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioButtonItem from '../RadioButtonItem';
import CheckboxGroup from '../CheckboxGroup';
import CheckboxItem from '../CheckboxItem';
import CheckboxField from '../CheckboxField';
import FieldHint from '../FieldHint';

// Import Styled Components
import StyledField from '../../styles/components/Field';
import StyledText from '../../styles/components/Text';
import Divider from '../../styles/components/Divider';

class Field extends Component {
  renderField() {
    const {
      type,
      onBlur,
      headerType,
      fixedActions,
      totalSteps,
      wide,
      paymentField,
      options,
      paddingRight,
      ...props
    } = this.props;

    if (type === 'legend') {
      return <Legend {...props} />;
    }

    if (type === 'select') {
      return (
        <SelectField {...props}>
          {options &&
            options.map(option => (
              <option {...option} id={`${props.name}-${option.id}`}>
                {option.label || option.value}
              </option>
            ))}
        </SelectField>
      );
    }

    if (type === 'checkbox') {
      return <CheckboxField {...props} />;
    }

    if (type === 'multiCheckbox') {
      return <CheckboxGroup {...props}>{options && options.map(option => <CheckboxItem {...option} />)}</CheckboxGroup>;
    }

    if (type === 'radio') {
      return (
        <RadioButtonGroup {...props}>
          {options.map(option => (
            <RadioButtonItem full {...props} {...option} />
          ))}
        </RadioButtonGroup>
      );
    }

    if (type === 'title') {
      return <Title type={headerType} {...props} />;
    }

    if (type === 'copy') {
      return <Text {...props} />;
    }

    if (type === 'textArea') {
      return <TextArea {...props} />;
    }

    if (type === 'stripe') {
      return <CardElement hidePostalCode />;
    }

    if (type === 'date') {
      return <DateField {...props} />;
    }

    if (type === 'divider') {
      return <Divider {...props} />;
    }

    // defaults to text input
    return <TextField type={type} {...props} />;
  }

  render() {
    const {
      id,
      name,
      hint,
      label,
      error,
      render,
      optional,
      type,
      info,
      wide,
      helpText,
      inline,
      paddingLeft,
      paddingRight,
      ...props
    } = this.props;

    // If field has a custom render prop - return here
    if (render) return render();

    // If hidden field return the input here
    // TODO: this doesnt seem to work well with formik
    if (type === 'hidden') return <input id={id} name={name} type={type} {...props} />;

    return (
      <StyledField modifiers={{ wide, inline, paddingLeft, paddingRight }}>
        {label && (
          <StyledField.Label.Wrapper>
            <StyledField.Label htmlFor={name}>{label}</StyledField.Label>
            {hint && <FieldHint>{hint}</FieldHint>}
          </StyledField.Label.Wrapper>
        )}
        {/* TODO: wrap in formik field, remove from all fields */}
        {this.renderField()}
        {helpText && <StyledText>{helpText}</StyledText>}
        {error && <StyledField.InlineError>{error}</StyledField.InlineError>}
        {info && <StyledField.InlineTooltip>{info}</StyledField.InlineTooltip>}
      </StyledField>
    );
  }
}

Field.propTypes = {
  error: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  fixedActions: PropTypes.bool,
  formData: PropTypes.shape({}),
  headerType: PropTypes.string,
  id: PropTypes.string.isRequired,
  info: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  optional: PropTypes.bool,
  paymentField: PropTypes.string,
  render: PropTypes.func,
  totalSteps: PropTypes.number,
  type: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
  wide: PropTypes.bool,
  inline: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  paddingRight: PropTypes.bool,
  hint: PropTypes.string,
  options: PropTypes.node,
  helpText: PropTypes.string,
};

Field.defaultProps = {
  error: null,
  fixedActions: false,
  formData: null,
  headerType: '',
  info: '',
  label: null,
  name: null,
  onBlur: null,
  optional: false,
  paymentField: null,
  render: null,
  totalSteps: 0,
  validate: null,
  wide: false,
  hint: '',
  options: null,
  helpText: null,
  inline: false,
  paddingLeft: false,
  paddingRight: false,
};

export default Field;
