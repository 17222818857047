// Import Dependencies
import styled from 'styled-components';

// Import Elements
// Import Gobals
import * as vars from '../../global/vars';

const CheckboxGroup = styled.div`
  margin: ${vars.spacing.small} 0;
`;

export default CheckboxGroup;
