// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

// Import Elements
import Image from './Image';
import Button from './Button';
import Iframe from './Iframe';

const Video = styled.div`
  background-color: ${vars.colors.grey200};
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

Video.Image = Image;
Video.Button = Button;
Video.Iframe = Iframe;

export default Video;
