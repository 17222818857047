// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';
import * as vars from '../../global/vars';

// Import Elements
import Background from './Background';
import Caption from './Caption';

const Image = styled.figure`
  background-color: ${vars.colors.grey200};
  display: block;
  overflow: hidden;
  padding-bottom: ${(2 / 3) * 100}%;
  position: relative;
  width: 100%;
  z-index: 1;

  img {
    display: none;
  }

  ${props =>
    getModifier(props, 'noAspectRatio') &&
    ` 
      padding-bottom: 0;
      height: auto;
  `};

  ${props =>
    getModifier(props, 'contain') &&
    ` 
    background-color: transparent;
    padding-bottom: 100%;
  `};

  ${props =>
    getModifier(props, 'contain') &&
    vars.breakpoint.medium`
    padding-bottom: ${(2 / 3) * 100}%;
  `};
`;

Image.Background = Background;
Image.Caption = Caption;

export default Image;
