// Import Dependencies
import styled from 'styled-components';

// Import Globals
import typography from '../../global/typography';

const Optional = styled.label`
  ${typography.smallText};
  opacity: 0.7;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export default Optional;
