import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';

// Import Components
import CmsCard from '../CmsCard';
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';
import StyledCardGallery from '../../styles/components/CardGallery';

import * as Icon from '../Icons';

const flickityOptions = {
  prevNextButtons: false,
  adaptiveHeight: false,
  contain: true,
  groupCells: true,
  pageDots: true,
  initialIndex: 0,
  wrapAround: false,
  cellAlign: 'left',
};

class CardGallery extends PureComponent {
  state = {
    flickityReady: false,
    previousIndex: 0,
  };

  componentDidMount = () => {
    this.flkty.on('ready', this.handleFlickityReady);
    this.flkty.on('dragMove', this.handleFlickityMove);
  };

  setRef = element => {
    this.flkty = element;
  };

  handleFlickitySelect = index => {
    const { previousIndex } = this.state;

    const delta = index - previousIndex;
    const lastIndex = this.flkty.slides.length - 1;

    this.setState(() => ({
      previousIndex: index,
    }));

    if (previousIndex === lastIndex && index === 0) {
      this.flkty.element.classList.remove('is-reverse');
      return;
    }

    if (previousIndex === 0 && index === lastIndex) {
      this.flkty.element.classList.add('is-reverse');
      return;
    }

    if (delta > 0) {
      this.flkty.element.classList.remove('is-reverse');
      return;
    }

    this.flkty.element.classList.add('is-reverse');
  };

  handleFlickityReady = () => {
    this.setState(() => ({
      flickityReady: true,
    }));
  };

  handleFlickityMove = (event, pointer, moveVector) => {
    const { x } = moveVector;
    if (x > 0) {
      this.flkty.element.classList.add('is-reverse');
      return;
    }

    this.flkty.element.classList.remove('is-reverse');
  };

  changeSlide = (e, left) => {
    if (left) {
      this.flkty.element.classList.add('is-reverse');
      setTimeout(() => {
        this.flkty.previous();
      }, 50);
      return;
    }

    this.flkty.element.classList.remove('is-reverse');
    setTimeout(() => {
      this.flkty.next();
    }, 50);
  };

  changeSlidePrevious = () => {
    this.changeSlide(null, true);
  };

  renderGallery = () => {
    const { cards, cardHeadline, ...props } = this.props;

    // only render in client
    if (typeof Flickity !== 'function') return false;

    return (
      <div>
        <Flickity options={flickityOptions} flickityRef={this.setRef}>
          {cards &&
            cards.length > 0 &&
            cards.map((card, i) => <CmsCard id={card.id} i={i} sliderCard {...card} {...props} />)}
        </Flickity>
        <StyledCardGallery.Actions>
          <StyledCardGallery.Button type="button" onClick={this.changeSlidePrevious}>
            <Icon.ArrowLeft />
          </StyledCardGallery.Button>
          <StyledCardGallery.Button type="button" onClick={this.changeSlide}>
            <Icon.ArrowRight />
          </StyledCardGallery.Button>
        </StyledCardGallery.Actions>
      </div>
    );
  };

  render() {
    const { cardHeadline } = this.props;
    const { flickityReady } = this.state;

    const { cardHeading: heading, description } = (cardHeadline && cardHeadline[0]) || {};

    return (
      <Section modifiers={{ center: true, dotsTopBottom: true, smallPaddingBottom: true }}>
        <Container>
          <Grid>
            <Grid.Item modifiers={{ cols: 12, colsMedium: 8, offsetMedium: 2 }}>
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                {heading && (
                  <Title type="h3" styleType="h2">
                    {heading}
                  </Title>
                )}
                {description && (
                  <Title type="h5" styleType="h3" modifiers={{ xLargeMargin: true }}>
                    {description}
                  </Title>
                )}
              </Grid.Inner>
            </Grid.Item>
          </Grid>

          <div className={`flickity  flickity-card  ${flickityReady && 'is-loaded'}`}>{this.renderGallery()}</div>
        </Container>
      </Section>
    );
  }
}

CardGallery.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cardHeadline: PropTypes.arrayOf(PropTypes.shape({})),
};

CardGallery.defaultProps = {
  cardHeadline: [],
};

export default CardGallery;
