// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const ErrorMessage = styled.p`
  ${typography.base};
  color: ${vars.colors.error};
`;

export default ErrorMessage;
