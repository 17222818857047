// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Utilities
import { getButtonElement } from '../../utils';

// Import Styled Components
import StyledLink from '../../styles/components/Link';

const Link = ({ children, icon, ...props }) => {
  const elementType = getButtonElement(props);
  const ElementType = StyledLink.withComponent(elementType);

  return <ElementType {...props}>{children}</ElementType>;
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

Link.defaultProps = {
  icon: null,
};

export default Link;
