// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';

// Import elements
import NavItemMarketing from './NavItemMarketing';
import NavMarketing from './NavMarketing';
import CarterLink from './CarterLink';

const FooterMarketing = styled.footer`
  background-color: ${vars.colors.grey500};
  color: ${vars.colors.white};
  padding: ${toRems('45px')} 0;
  width: 100vw;

  ${vars.breakpoint.medium`
    padding: ${vars.spacing.large} 0;
  `};

  p {
    font-size: ${vars.fontSize.xSmall};
    font-weight: ${vars.fontWeight.medium};

    ${vars.breakpoint.medium`
      font-size: ${vars.fontSize.small};
    `};
  }
`;

// Set elements
FooterMarketing.Nav = NavMarketing;
FooterMarketing.Item = NavItemMarketing;
FooterMarketing.CarterLink = CarterLink;

export default FooterMarketing;
