// Import Dependencies
import styled from 'styled-components';

// Import elements
import Item from './Item';
import Inner from './Inner';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';

const Grid = styled.ul`
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -${vars.grid.gutterValue / 2}px;

  @media print {
    height: auto;
  }

  ${props =>
    getModifier(props, 'center') &&
    `
      align-items: center;
  `};

  ${props =>
    getModifier(props, 'justifyCenter') &&
    `
      justify-content: center;
  `};

  ${props =>
    getModifier(props, 'reverse') &&
    vars.breakpoint.medium`
      flex-flow: row-reverse;
  `};
`;

// Set elements
Grid.Item = Item;
Grid.Inner = Inner;

export default Grid;
