// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import ImageLoader from '../ImageLoader';
import Title from '../Title';
import Video from '../Video';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';
import Wysiwyg from '../../styles/components/Wysiwyg';
import cleanHtml from '../../utils/cleanHtml';

const SplitContentBlock = ({ splitContent, storybook }) =>
  splitContent.map(item => {
    const {
      splitContentHeading: heading,
      body,
      toggleDisplayRight: reverse,
      image,
      backgroundImage,
      toggleVideo,
      youtubeId,
    } = item;

    const imageUrl = image && image[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${image[0].url}`;

    const bgImageUrl =
      backgroundImage &&
      backgroundImage[0] &&
      `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${backgroundImage[0].url}`;

    return (
      <Section modifiers={{ dark: true, split: true, dotsLeft: reverse, dotsRight: !reverse }}>
        <Container>
          <Grid modifiers={{ center: true, reverse }}>
            <Grid.Item
              modifiers={{ colsSmall: 5, colsMedium: 4, offsetSmall: reverse ? 1 : 0, offsetMedium: reverse ? 2 : 0 }}
            >
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                <Title type="h3" styleType="h2" modifiers={{ white: true }}>
                  {heading}
                </Title>
                {body && body.content && <Wysiwyg dangerouslySetInnerHTML={{ __html: cleanHtml(body.content) }} />}
              </Grid.Inner>
            </Grid.Item>

            <Grid.Item
              modifiers={{
                colsSmall: 6,
                colsMedium: 6,
                offsetSmall: reverse ? 0 : 1,
                offsetMedium: reverse ? 0 : 2,
                relativeOnMobile: !!bgImageUrl,
                marginTopOnMobile: true,
              }}
            >
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                <Section.Image modifiers={{ splitRight: !reverse, splitLeft: reverse, mobile: !!bgImageUrl }}>
                  {bgImageUrl && <ImageLoader modifiers={{ noAspectRatio: true, blur: true }} imageUrl={bgImageUrl} />}
                </Section.Image>

                {toggleVideo ? (
                  <Video poster={imageUrl} youtubeId={youtubeId} />
                ) : (
                  <ImageLoader modifiers={{ contain: !!bgImageUrl }} imageUrl={imageUrl} />
                )}
              </Grid.Inner>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  });

SplitContentBlock.propTypes = {
  splitContent: PropTypes.arrayOf(PropTypes.shape({})),
  storybook: PropTypes.bool,
};

SplitContentBlock.defaultProps = {
  storybook: false,
};

export default SplitContentBlock;
