// Import Dependencies
import styled from 'styled-components';

// Import Elements
// Import Gobals

const RadioButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  vertical-align: baseline;

  &:after {
    content: '';
    clear: both;
    display: table;
  }
`;

export default RadioButtonGroup;
