// Import Dependencies
import { css } from 'styled-components';

// Import Globals
import * as vars from './vars';

// Mixin
const listMixin = css`
  font-size: ${vars.fontSize.small};
  list-style-type: none;
  margin-bottom: ${vars.spacing.small};
  overflow: hidden;
  padding: 0;
  width: 100%;

  ${vars.breakpoint.medium`
    font-size: ${vars.fontSize.base};
    margin-bottom: ${vars.spacing.base};
  `}

  li {
    margin-bottom: ${vars.spacing.small};
    padding-left: ${vars.spacing.medium};
    position: relative;

    ${vars.breakpoint.medium`
      margin-bottom: ${vars.spacing.medium};
    `}

    &:before {
      color: ${vars.colors.blue500};
      display: inline-block;
      font-weight: ${vars.fontWeight.bold};
      left: 0;
      position: absolute;
      top: 0;
      vertical-align: middle;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default listMixin;
