// Import Dependencies
import styled from 'styled-components';

import * as vars from '../../global/vars';
import getModifier from '../../../utils/getModifier';

const Header = styled.h4`
  color: ${vars.colors.grey300};
  font-size: ${vars.fontSize.xxxLarge};

  ${props =>
    getModifier(props, 'dark') &&
    `
      color: ${vars.colors.grey500};
  `};
`;

export default Header;
