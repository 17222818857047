// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledCheckbox from '../../styles/components/Checkbox';

const Checkbox = ({ value, name, label, ...props }) => {
  const id = `${name}-${value}`;
  return (
    <StyledCheckbox>
      <StyledCheckbox.Input type="checkbox" id={id} {...props} />
      <StyledCheckbox.Label htmlFor={id}>{label}</StyledCheckbox.Label>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
};

Checkbox.defaultProps = {
  value: null,
  label: null,
  error: null,
};

export default Checkbox;
