// Import Dependencies
import styled from 'styled-components';

// Import Elements
import * as vars from '../../global/vars';
import typography from '../../global/typography';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const RadioButtonItem = styled.button.attrs({ type: 'button' })`
  ${typography.smallText}
  appearance: none;
  background-color: #fff;
  border: ${props => (getModifier(props, 'hasError') ? vars.colors.red500 : vars.colors.blue200)} solid 2px !important;
  bottom: 0;
  display: block;
  margin: 0;
  padding: 12px 20px;
  transition: background ${vars.global.transitionSpeed} ${vars.global.easing};
  width: 100%;

  &:hover,
  &:focus,
  &.is-active {
    background-color: ${vars.colors.blue200};
  }

  &:focus,
  &.is-active {
    border: ${props => (getModifier(props, 'hasError') ? vars.colors.red500 : vars.colors.blue200)} solid 2px !important;
    font-weight: 500;
  }

  &.is-active + button {
    border-left-color: transparent;
  }

  ${props =>
    getModifier(props, 'full') &&
    `
    flex: 1;  
  `};
`;

const Wrapper = styled.div`
  flex: 1;
  width: 100%;

  span {
    ${typography.smallText}
    color: ${vars.colors.grey400};
    display: block;
    opacity: 0.5;
    padding: 0 0 0 5px;
    text-align: left;
    
    ${props =>
      getModifier(props, 'rightPadding') &&
      `
        padding-right: 20%;
    `};
  }
`;

RadioButtonItem.Wrapper = Wrapper;

export default RadioButtonItem;
