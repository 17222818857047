// Import dependencies
import React from 'react';

// Import Components
import NavMarketing from '../NavMarketing';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Wysiwyg from '../../styles/components/Wysiwyg';
import StyledFooter from '../../styles/components/FooterMarketing';

const FooterMarketing = ({ ...props }) => {
  return (
    <StyledFooter {...props}>
      <Container>
        <Grid>
          <Grid.Item modifiers={{ colsSmall: 6, colsMedium: 9 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              <NavMarketing footerNav {...props} />
            </Grid.Inner>
          </Grid.Item>
          <Grid.Item modifiers={{ colsSmall: 6, colsMedium: 3 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              <Wysiwyg modifiers={{ greyLink: true, smallBottomMargin: true, alignTextRightOnMedium: true }}>
                <p>©2019 The Workability Index</p>
                <StyledFooter.CarterLink>
                  Made at
                  {' '}
                  <a href="https://carterdigital.com.au/" target="_blank" rel="noopener noreferrer">
                    Carter.
                  </a>
                </StyledFooter.CarterLink>
              </Wysiwyg>
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default FooterMarketing;
