// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';
import Link from '../Link';
import Title from '../Title';

// Import Styled Components
import ButtonList from '../../styles/components/ButtonList';
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import LeadersList from '../../styles/components/LeadersList';
import Section from '../../styles/objects/Section';

// Import Utilities
import { generateLink } from '../../utils';

const increment = 5;

class LeadersBlock extends PureComponent {
  state = { limit: 5 };

  handleLoadMore = () => {
    this.setState(prevState => ({
      limit: prevState.limit + increment,
    }));
  };

  render() {
    const { leaderHeadline, topPerformers, cta } = this.props;
    const { limit } = this.state;
    const { headlineHeading: heading, description } = (leaderHeadline && leaderHeadline[0]) || {};
    const { ctaText, ctaInternalEntry, ctaSwitch, ctaExternalLink } = (cta && cta[0]) || {};

    const showLoadMore = topPerformers.length > limit;

    return (
      <Section modifiers={{ dark: true, center: true, gradient: true }}>
        <Container>
          <Grid>
            <Grid.Item modifiers={{ colsMedium: 8, offsetMedium: 2 }}>
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                {heading && (
                  <Title type="h3" styleType="h2" modifiers={{ white: true }}>
                    {heading}
                  </Title>
                )}
                {description && (
                  <Title type="h5" styleType="h3" modifiers={{ xLargeMargin: true, white: true }}>
                    {description}
                  </Title>
                )}

                <LeadersList>
                  {topPerformers.map((item, i) => (
                    <LeadersList.Item key={item.id} modifiers={{ hideOnMobile: i >= limit }}>
                      <LeadersList.Number>{i + 1}</LeadersList.Number>
                      <span>{item.organisationName}</span>
                      <LeadersList.Score>
                        <strong>{item.twiScore < 500 ? '< 500' : item.twiScore}</strong>
                        {' / 1000'}
                      </LeadersList.Score>
                    </LeadersList.Item>
                  ))}

                  {showLoadMore && (
                    <LeadersList.LoadMore>
                      <Link modifiers={{ white: true }} onClick={this.handleLoadMore}>
                        Load more
                      </Link>
                    </LeadersList.LoadMore>
                  )}
                </LeadersList>

                {ctaText && (
                  <ButtonList modifiers={{ paddingBottomMobile: true }}>
                    <li>
                      <Button {...generateLink(ctaInternalEntry, ctaSwitch, ctaExternalLink)}>{ctaText}</Button>
                    </li>
                  </ButtonList>
                )}
              </Grid.Inner>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
}

LeadersBlock.propTypes = {
  cta: PropTypes.arrayOf(PropTypes.shape({})),
  leaderHeadline: PropTypes.arrayOf(PropTypes.shape({})),
  topPerformers: PropTypes.arrayOf(PropTypes.shape({})),
};

LeadersBlock.defaultProps = {
  cta: [],
  leaderHeadline: [],
  topPerformers: [],
};

export default LeadersBlock;
