// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';
import { calculateGridPercentage } from '../../global/utils';

const Item = styled.li`
  flex: 1 0 100%;
  width: 100%;

  @media print {
    height: auto;
  }

  ${props =>
    getModifier(props, 'cols') &&
    `
    flex: 0 1 ${calculateGridPercentage(getModifier(props, 'cols'), vars.grid.columns) || 'auto'};
    width: ${calculateGridPercentage(getModifier(props, 'cols'), vars.grid.columns) || 'auto'};
  `};

  ${props =>
    getModifier(props, 'colsSmall') &&
    vars.breakpoint.small`
    flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsSmall'), vars.grid.columns) || 'auto'}`};
    width: ${calculateGridPercentage(getModifier(props, 'colsSmall'), vars.grid.columns) || 'auto'};
  `};

  ${props =>
    getModifier(props, 'colsMedium') &&
    vars.breakpoint.medium`
    flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsMedium'), vars.grid.columns) || 'auto'}`};
    width: ${calculateGridPercentage(getModifier(props, 'colsMedium'), vars.grid.columns) || 'auto'};
  `};

  ${props =>
    getModifier(props, 'colsLarge') &&
    vars.breakpoint.large`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsLarge'), vars.grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsLarge'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'colsXLarge') &&
    vars.breakpoint.xLarge`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsXLarge'), vars.grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsXLarge'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'offset') &&
    `
      margin-left: ${calculateGridPercentage(getModifier(props, 'offset'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'offsetSmall') &&
    vars.breakpoint.small`
      margin-left: ${calculateGridPercentage(getModifier(props, 'offsetSmall'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'offsetMedium') &&
    vars.breakpoint.medium`
      margin-left: ${calculateGridPercentage(getModifier(props, 'offsetMedium'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'offsetLarge') &&
    vars.breakpoint.large`
      margin-left: ${calculateGridPercentage(getModifier(props, 'offsetLarge'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'offsetXLarge') &&
    vars.breakpoint.xLarge`
      margin-left: ${calculateGridPercentage(getModifier(props, 'offsetXLarge'), vars.grid.columns)};
  `};

  ${props =>
    getModifier(props, 'mobileMargin') &&
    `
      margin-top: ${vars.spacing.small};
      margin-bottom: ${vars.spacing.small};
    `};

  ${props =>
    getModifier(props, 'mobileMargin') &&
    vars.breakpoint.medium`
      margin-top: 0;
      margin-bottom: 0;
  `};

  ${props =>
    getModifier(props, 'relativeOnMobile') &&
    `
    position: relative;
  `};

  ${props =>
    getModifier(props, 'relativeOnMobile') &&
    vars.breakpoint.medium`
    position: static;
  `};

  ${props =>
    getModifier(props, 'marginTopOnMobile') &&
    `
    margin-top: ${vars.spacing.medium};
  `};

  ${props =>
    getModifier(props, 'marginTopOnMobile') &&
    vars.breakpoint.medium`
    margin-top: 0;
  `};

  ${props =>
    getModifier(props, 'marginBottomOnMobile') &&
    `
    margin-top: ${vars.spacing.medium};
  `};

  ${props =>
    getModifier(props, 'marginBottomOnMobile') &&
    vars.breakpoint.medium`
    margin-top: 0;
  `};

  ${props =>
    getModifier(props, 'hideOnMobile') &&
    `
      display: none;
  `};

  ${props =>
    getModifier(props, 'hideOnMobile') &&
    vars.breakpoint.medium`
      display: block;
  `};

  ${props =>
    getModifier(props, 'splitPrint') &&
    `
      @media print {
        flex: 1 0 50%;
        width: 50%;
      }
  `};
`;

export default Item;
