// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Input from './Input';
import Label from './Label';

// Import Gobals
import * as vars from '../../global/vars';

const Checkbox = styled.div`
  margin: ${vars.spacing.xSmall} 0;
  position: relative;
  transition: all ${vars.global.transitionSpeed} ${vars.global.easing};

  input {
    opacity: 0;

    & + label:after {
      opacity: 0;
    }

    &:focus {
      + label:before {
        border-color: ${vars.colors.blue500};
        background-color: ${vars.colors.blue200};
      }
    }

    &:checked {
      + label:before {
        /* border-color: ${vars.colors.blue500}; */
        background-color: ${vars.colors.blue200};
      }
      + label:after {
        opacity: 1;
      }
    }
  }
`;

Checkbox.Input = Input;
Checkbox.Label = Label;

export default Checkbox;
