// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';
import Wysiwyg from '../../styles/components/Wysiwyg';
import cleanHtml from '../../utils/cleanHtml';

const WysiwygBlock = ({ wysiwyg }) => {
  const {
    wysiwygHeading: heading,
    subheading,
    body: { content },
  } = wysiwyg[0];

  return (
    <Section>
      <Container>
        <Grid>
          <Grid.Item modifiers={{ colsMedium: 8, offsetMedium: 2 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              <Title type="h3" styleType="h2">
                {heading}
              </Title>
              {subheading && (
                <Title type="h5" styleType="h3">
                  {subheading}
                </Title>
              )}
              {content && <Wysiwyg dangerouslySetInnerHTML={{ __html: cleanHtml(content) }} />}
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
};

WysiwygBlock.propTypes = {
  wysiwyg: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WysiwygBlock;
