// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import Globals
import * as vars from '../../global/vars';

const Image = styled.div`
  display: none;

  ${vars.breakpoint.medium`
    bottom: -${vars.components.sectionImageOffsetBottom};
    display: flex;
    left: 0;
    min-width: ${vars.components.sectionImageWidth};
    position: absolute;
    top: 0;
    width: calc(((100% - ${vars.grid.maxWidth}) / 2) + ${vars.components.sectionImageWidth});
  `};

  ${props =>
    getModifier(props, 'splitRight') &&
    vars.breakpoint.medium`
      background-color: ${vars.colors.white};
      bottom: 0;
      left: auto;
      right: 0;
      width: 55%;
  `};

  ${props =>
    getModifier(props, 'splitLeft') &&
    vars.breakpoint.medium`
      background-color: ${vars.colors.white};
      bottom: 0;
      left: 0;
      right: auto;
      width: 55%;
  `};

  ${props =>
    getModifier(props, 'highlights') &&
    vars.breakpoint.medium`
      bottom: 120px;
      top: 120px;
      max-width: none;
      width: calc(((100% - ${vars.grid.maxWidth}) / 2) + ${vars.components.sectionImageWidth});
  `};

  ${props =>
    getModifier(props, 'mobile') &&
    `
      bottom: 0;
      display: block;
      display: flex;
      left: -${vars.spacing.base};
      position: absolute;
      right: -${vars.spacing.base};
      top: 0;
  `};
`;

export default Image;
