// Import Dependencies
import { css } from 'styled-components';

// Mixin
export const visuallyHidden = css`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px);

  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
`;
