// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

// Import Utils
import getModifier from '../../../utils/getModifier';

const Actions = styled.div`
  margin-top: 5px;

  ${vars.breakpoint.small`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    min-width: 200px;
    padding-left: 30px;
`}

  ${vars.breakpoint.medium`
    display: block;
  `};
`;

const Alert = styled.div`
  ${props => (getModifier(props, 'success') ? typography.success : typography.alert)}

  ${vars.breakpoint.small`
    display: flex;
    justify-content: flex-start;
  `};

  a,
  button {
    margin-left: ${vars.spacing.xSmall};
    margin-right: ${vars.spacing.xSmall};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    ${vars.breakpoint.small`
      margin-left: 0;
      margin-right: 0;
    `};

    ${vars.breakpoint.medium`
      margin-left: ${vars.spacing.xSmall};
      margin-right: ${vars.spacing.xSmall};
    `};
  }
`;

Alert.Actions = Actions;

export default Alert;
