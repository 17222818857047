// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import * as vars from '../../global/vars';

const LoadMore = styled.li`
  margin: ${vars.spacing.medium};
  text-align: center;

  ${vars.breakpoint.medium`
    display: none;
  `}
`;

export default LoadMore;
