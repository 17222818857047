// Import Dependencies
import styled from 'styled-components';

// Import Globals
import typography from '../../global/typography';
import * as vars from '../../global/vars';

const Title = styled.h3`
  ${typography.headingLarge};
  margin-bottom: ${vars.spacing.medium};
  text-align: left;
`;

export default Title;
