// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const Label = styled.label`
  ${typography.smallText}
  font-weight: bold;
  color: ${vars.colors.grey500};
  display: block;
  margin-bottom: 5px;
`;

export default Label;
