// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';

const Image = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.1);
  transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s,
    transform ${vars.global.transitionSpeed} ${vars.global.easing} 0s;

  img {
    display: none;
  }

  ${props =>
    getModifier(props, 'isLoaded') &&
    `
    opacity: 1;
    transform: none;
  `};
`;

export default Image;
