// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';
import Title from '../Title';

// Import Styled Components
import ButtonList from '../../styles/components/ButtonList';
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';
import Wysiwyg from '../../styles/components/Wysiwyg';

// Import Utilities
import { generateLink } from '../../utils';
import cleanHtml from '../../utils/cleanHtml';

const CtaBlock = ({ ctaHeadline, cta }) => {
  const { headlineHeading: heading, description } = ctaHeadline[0];

  return (
    <Section modifiers={{ dark: true, center: true, dots: true, dotsWhite: true, largePadding: true }}>
      <Container>
        <Grid>
          <Grid.Item modifiers={{ colsMedium: 8, offsetMedium: 2 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              <Title type="h3" styleType="h2" modifiers={{ white: true }}>
                {heading}
              </Title>
              {description && <Wysiwyg dangerouslySetInnerHTML={{ __html: cleanHtml(description) }} />}
              <ButtonList>
                {cta &&
                  cta.map((action, i) => {
                    const { id, ctaSwitch, ctaExternalLink, ctaInternalEntry, ctaText } = action;

                    return (
                      <li key={id}>
                        <Button
                          modifiers={{ secondary: i > 0 }}
                          {...generateLink(ctaInternalEntry, ctaSwitch, ctaExternalLink)}
                        >
                          {ctaText}
                        </Button>
                      </li>
                    );
                  })}
              </ButtonList>
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
};

CtaBlock.propTypes = {
  cta: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ctaHeadline: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CtaBlock;
