// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

import Wysiwyg from '../Wysiwyg';

const PlanCardWysiwyg = styled(Wysiwyg)`
  ul {
    margin-bottom: 0;
    li {
      margin-bottom: ${vars.spacing.xSmall};
    }
  }
`;

export default PlanCardWysiwyg;
