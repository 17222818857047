// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledAlert from '../../styles/components/Alert';

const Alert = ({ children, actions, ...props }) => {
  return (
    <StyledAlert {...props}>
      <div>{children}</div>
      {actions && <StyledAlert.Actions>{actions()}</StyledAlert.Actions>}
    </StyledAlert>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.func,
};

Alert.defaultProps = {
  actions: null,
};

export default Alert;
