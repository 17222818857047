// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import { hexToRgba } from '../../global/utils';

const CarterLink = styled.p`
  color: ${hexToRgba(vars.colors.white, 0.3)};

  a {
    opacity: 0.3;
    transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s;

    &:hover {
      box-shadow: none;
      opacity: 1;
      transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s;
    }
  }
`;

export default CarterLink;
