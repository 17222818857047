// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const ArrowLeft = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M427.8 227.8H125.9l95.4-95.4c8.7-8.7 8.7-22.7 0-31.4s-22.7-8.7-31.4 0L56.5 234.3c-8.7 8.7-8.7 22.7 0 31.4L189.8 399c4.3 4.3 10 6.5 15.7 6.5s11.4-2.2 15.7-6.5c8.7-8.7 8.7-22.7 0-31.4l-95.4-95.4h301.9c12.3 0 22.2-9.9 22.2-22.2s-9.9-22.2-22.1-22.2z" id="Layer_18" />
  </Icon>
);

export default ArrowLeft;
