/**
 * Plugin Styles
 *
 * Usage:
 * import '../plugins/'
 */

// Plugin component styles
import flickity from '../plugins/flickity';
import nprogress from '../plugins/nprogress';
import dayPicker from '../plugins/dayPicker';

// Global CSS Styles
const plugins = `
  ${nprogress}
  ${flickity}
  ${dayPicker}
`;

export default plugins;
