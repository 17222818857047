// Import Dependencies
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledButton from '../../styles/components/Button';

const Button = ({ children, href, ...props }) => {
  if (href) {
    return (
      <StyledButton {...props} as="a" href={href}>
        {children}
      </StyledButton>
    );
  }

  return <StyledButton {...props}>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

Button.defaultProps = {
  href: null,
};

export default memo(Button);
