// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

// Import Utilities
import { hexToRgba } from '../../global/utils';
import getModifier from '../../../utils/getModifier';

const Button = styled.button`
  &,
  &:link,
  &:visited {
    ${typography.label};
    -webkit-appearance: none;
    background-color: ${vars.colors.blue500};
    background-image: ${vars.gradients.blue};
    background-position: 35% 50%;
    background-size: 400% 400%;
    border-radius: 0;
    color: ${vars.colors.white};
    cursor: pointer;
    display: inline-block;
    min-width: 180px;
    overflow: hidden;
    padding: 12px ${vars.spacing.base};
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-position ${vars.global.transitionSpeedLong} ${vars.global.easing} 0s;
    vertical-align: middle;

    ${vars.breakpoint.medium`
      max-width: 250px;
      padding: 19px ${vars.spacing.base};
      width: 100%;
    `}
  }

  &:hover,
  &:active,
  &:focus {
    background-position: 100% 50%;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    display: block;
    fill: ${vars.colors.white};
    flex: 0 0 ${vars.spacing.xSmall};
    height: ${vars.spacing.xSmall};
    width: ${vars.spacing.xSmall};
  }

  /* Upload button field */
  input {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  /* Modifier: Text link */
  ${props =>
    getModifier(props, 'inlineLink') &&
    `
    &,
    &:link,
    &:visited {
      ${typography.textLink}
      min-width: 0;
    }
  `};

  /* Modifier: Secondary button */
  ${props =>
    getModifier(props, 'secondary') &&
    `
    &,
    &:link,
    &:visited {
      background-image: ${vars.gradients.grey};
    }
  `};

  /* Modifier: Has Icon */
  ${props =>
    getModifier(props, 'hasIcon') &&
    `
      &,
      &:link,
      &:visited {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding: 17px ${vars.spacing.base};

        svg {
          display: block;
          fill: ${vars.colors.white};
          flex: 0 0 24px;
          height: 24px;
          margin-right: ${vars.spacing.small};
          width: 24px;
        }
      }
  `};

  /* Modifier: smallWidth */
  ${props =>
    getModifier(props, 'smallWidth') &&
    `
    &,
    &:link,
    &:visited {
      align-self: stretch;
      font-weight: ${vars.fontWeight.bold};
      min-width: 0;
      width: 150px;
    }
  `};

  ${props =>
    getModifier(props, 'smallWidth') &&
    vars.breakpoint.large`
    &,
    &:link,
    &:visited {
      width: 185px;
    }
  `};

  /* Modifier: largeWidth */
  ${props =>
    getModifier(props, 'largeWidth') &&
    `
    &,
    &:link,
    &:visited {
      min-width: 250px;
    }
  `};

  /* Modifier: midWidth */
  ${props =>
    getModifier(props, 'midWidth') &&
    `
    &,
    &:link,
    &:visited {
      min-width: 200px;
    }
  `};

  /* Modifier: fullWidthMobile */
  ${props =>
    getModifier(props, 'fullWidthMobile') &&
    `
    &,
    &:link,
    &:visited {
      width:100%;
      margin-bottom: 15px;
    }
  `};

  ${props =>
    getModifier(props, 'fullWidthMobile') &&
    vars.breakpoint.medium`
    &,
    &:link,
    &:visited {
      width: auto;
    }
  `};

  /* Modifier: Side Navigation */
  ${props =>
    getModifier(props, 'sideNavBtn') &&
    `
      &,
      &:link,
      &:visited {
        background-color: ${vars.colors.grey500};
        background-image: none;
        bottom: 0;
        flex-direction: column;
        font-weight: ${vars.fontWeight.medium};
        justify-content: center;
        left: 1px;
        min-width: 0;
        overflow: visible;
        padding: 12px 13px;
        position: absolute;
        right: 1px;
        top: 1px;
        transition: background-color ${vars.global.transitionSpeed} ${vars.global.easing} 0s;
        width: calc(100% - 1px);

        svg {
          flex: 0 0 auto;
          fill: ${hexToRgba(vars.colors.white, 0.3)};
          margin: 0 0 ${vars.spacing.xxSmall} 0;
        }
      }

      &:hover,
      &:focus {
        background-color: ${vars.colors.grey400};
      }
  `};

  /* Modifier: Side Navigation */
  ${props =>
    getModifier(props, 'sideNavBtn') &&
    vars.breakpoint.medium`
      &,
      &:link,
      &:visited {
        background-color: transparent;
        border-radius: 5px;
        flex-direction: row;
        justify-content: flex-start;
        position: static;
        width: 100%;

        &:after {
          border-radius: 5px;
          box-shadow: 0 8px 16px ${hexToRgba(vars.colors.black, 0.5)};
          content: '';
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s;
          width: 100%;
        }

        svg {
          margin: 0 ${vars.spacing.small} 0 0;
        }
      }
  `};

  /* Modifier: Side Navigation - Active state */
  ${props =>
    getModifier(props, 'sideNavBtn') &&
    getModifier(props, 'isActive') &&
    `
      &,
      &:link,
      &:visited {
        background-color: ${vars.colors.blue500};

        &:after {
          opacity: 1;
        }

        svg {
          fill: ${vars.colors.white};
        }
      }
  `};

  /* Modifier: hideOnDesktop */
  ${props =>
    getModifier(props, 'hideOnDesktop') &&
    `
      display: block;
  `};

  ${props =>
    getModifier(props, 'hideOnDesktop') &&
    vars.breakpoint.medium`
    display: none;
  `};

  /* Modifier: headerButtonOnDesktop */
  ${props =>
    getModifier(props, 'headerButtonOnDesktop') &&
    vars.breakpoint.medium`
      &,
      &:link,
      &:visited {
        line-height: calc(${vars.components.headerHeightDesktop} / 2);
      }
  `};

  ${props =>
    getModifier(props, 'transparent') &&
    `
      &,
      &:link,
      &:visited {
        background: transparent;

        svg {
          fill: ${vars.colors.blue500};
        }
      }
  `};

  ${props =>
    getModifier(props, 'smallIcon') &&
    `
      &,
      &:link,
      &:visited {
        background: transparent;

        svg {
          flex: 0 0 15px;
          height: 15px;
          width: 15px;
        }
      }
  `};

  ${props =>
    getModifier(props, 'iconOnly') &&
    `
      &,
      &:link,
      &:visited {
        align-items: center;
        display: flex;
        justify-content: center;
        min-width: 0;
        width: 100%;

        svg {
          display: block;
          fill: ${vars.colors.white};
          flex: 0 0 ${vars.spacing.base};
          height: ${vars.spacing.base};
          width: ${vars.spacing.base};
        }
      }
  `};

  ${props =>
    getModifier(props, 'hideTextOnMobile') &&
    `
      &,
      &:link,
      &:visited {
        font-size: 0;
        min-width: 0;
        width: 100%;

        svg {
          margin: 0;
        }
      }
  `};

  ${props =>
    getModifier(props, 'hideTextOnMobile') &&
    vars.breakpoint.medium`
      &,
      &:link,
      &:visited {
        ${typography.label};
        min-width: 160px;
        width: auto;

        svg {
          margin-right: ${vars.spacing.small};
        }
      }
  `};

  ${props =>
    getModifier(props, 'modalClose') &&
    `
      &,
      &:link,
      &:visited {
        height: 68px;
        width: 65px;

        svg {
          height: 16px;
          margin: 0 auto;
          width: 16px;
        }
      }
  `};
`;

export default Button;
