/* eslint-disable react/prop-types */
// Import dependencies
import React from 'react';
import { FieldArray } from 'formik';

// import PropTypes from 'prop-types';

// Import Components
import StyledField from '../../styles/components/Field';
import StyledCheckboxGroup from '../../styles/components/CheckboxGroup';

const CheckboxGroup = ({ name, caption, children }) => {
  return (
    <FieldArray name={name}>
      {({ push, remove, form: { errors, touched, values } }) => {
        const showError = errors[name] && touched[name];

        return (
          <StyledField.Wrapper>
            <StyledCheckboxGroup>
              {React.Children.map(children, child =>
                React.cloneElement(child, {
                  key: child.props.value,
                  name,
                  checked: values[name] && values[name].includes(child.props.value),
                  onChange: e => {
                    if (e.target.checked) push(child.props.value);
                    else {
                      const idx = values[name].indexOf(child.props.value);
                      remove(idx);
                    }
                  },
                }),
              )}
            </StyledCheckboxGroup>
            {showError && <StyledField.InlineError>{errors[name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </FieldArray>
  );
};

export default CheckboxGroup;
