// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const NavMarketing = styled.div`
  align-items: flex-start;
  background-color: ${vars.colors.grey500};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  left: 0;
  opacity: 0;
  overflow-x: hidden;
  padding: 20px ${vars.spacing.midLarge} 50px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 60px;
  transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing};

  /* Lets fade in background first then content */
  > * {
    opacity: 0;
    transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 280ms;
  }

  ${vars.breakpoint.medium`
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    height: auto;
    justify-content: flex-end;
    opacity: 1;
    padding: 0 0 0 ${vars.spacing.large};
    pointer-events: all;
    position: relative;
    top: 0;
    transform: translateX(0);


    > * {
      opacity: 1;
    }
  `};

  ul {
    align-items: flex-start;
    background-color: ${vars.colors.grey500};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${vars.breakpoint.medium`
      align-items: center;
      flex-direction: row;
      flex-grow: 2;
      justify-content: flex-end;
    `}

    &:hover {
      li {
        opacity: 0.3;

        &:visited {
          opacity: 0.3;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  /* Modifier: mobileNavShowHide */
  ${props =>
    getModifier(props, 'isMobileNavOpen') &&
    `
      opacity: 1;
      pointer-events: all;


      > * {
        opacity: 1;
      }
  `};
`;

export default NavMarketing;
