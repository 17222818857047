/* eslint-disable react/prop-types */
// Import Dependencies
import React from 'react';
import { Field } from 'formik';

// Import Styled Components
import StyledTextInput from '../../styles/components/TextInput';
import StyledField from '../../styles/components/Field';

const TextField = ({ name, caption, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => {
        const showError = errors[field.name] && touched[field.name];
        return (
          <StyledField.Wrapper>
            <StyledTextInput hasError={showError}>
              <input
                {...field}
                {...props}
                id={field.name}
                onBlur={e => {
                  field.onBlur(e);
                }}
              />
            </StyledTextInput>
            {showError && <StyledField.InlineError htmlFor={field.name}>{errors[field.name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption htmlFor={field.name}>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </Field>
  );
};
export default TextField;
