import { css } from 'styled-components';

export default css`
  @font-face {
    font-family: 'Gilmer';
    src: url('/static/fonts/gilmer-light.eot');
    src: url('/static/fonts/gilmer-light.woff2') format('woff2'), url('/static/fonts/gilmer-light.woff') format('woff'),
      url('/static/fonts/gilmer-light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url('/static/fonts/gilmer-regular.eot');
    src: url('/static/fonts/gilmer-regular.woff2') format('woff2'),
      url('/static/fonts/gilmer-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url('/static/fonts/gilmer-medium.eot');
    src: url('/static/fonts/gilmer-medium.woff2') format('woff2'),
      url('/static/fonts/gilmer-medium.woff') format('woff'), url('/static/fonts/gilmer-medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url('/static/fonts/gilmer-bold.eot');
    src: url('/static/fonts/gilmer-bold.woff2') format('woff2'), url('/static/fonts/gilmer-bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
  }

  @media print {
    @font-face {
      font-family: 'Gilmer';
      src: url('/static/fonts/gilmer-regular.eot');
      src: url('/static/fonts/gilmer-regular.woff2') format('woff2'),
        url('/static/fonts/gilmer-regular.woff') format('woff');
      font-style: normal;
      font-weight: 400;
    }

    @font-face {
      font-family: 'Gilmer';
      src: url('/static/fonts/gilmer-medium.eot');
      src: url('/static/fonts/gilmer-medium.woff2') format('woff2'),
        url('/static/fonts/gilmer-medium.woff') format('woff'),
        url('/static/fonts/gilmer-medium.ttf') format('truetype');
      font-style: normal;
      font-weight: 500;
    }
  }
`;
