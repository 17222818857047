// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Select from '../Select';
// Import Styled Components
import StyledPlanSelector from '../../styles/components/PlanSelector';
import { HideOnDesktop } from '../../styles/global/responsive';

const PlanSelector = ({ selectedPlan, handleChange, options }) => (
  <StyledPlanSelector>
    <StyledPlanSelector.Label>Select the number of participants for your surveys</StyledPlanSelector.Label>
    <div>
      {options.map(plan => (
        <StyledPlanSelector.Button
          modifiers={{
            isSelected: plan.id === selectedPlan,
          }}
          id={plan.id}
          key={plan.id}
          value={plan.value}
          onClick={handleChange}
          data-max-participants={plan.maxParticipants}
        >
          {plan.maxParticipants}
        </StyledPlanSelector.Button>
      ))}
    </div>

    <HideOnDesktop>
      <Select
        id="planselector-select"
        onChange={handleChange}
        options={options.map(option => ({
          ...option,
          selected: option.id === selectedPlan,
        }))}
      />
    </HideOnDesktop>
  </StyledPlanSelector>
);

PlanSelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPlan: PropTypes.string,
};

PlanSelector.defaultProps = {
  handleChange: null,
  options: null,
  selectedPlan: null,
};

export default PlanSelector;
