// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import vars
import * as vars from '../../global/vars';

const Iframe = styled.div`
  height: 0;
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: ${(2 / 3) * 100}%;
  position: relative;
  transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing} 0s,
    transform ${vars.global.transitionSpeed} ${vars.global.easing} 0s;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  transform: scale(1.1);
  visibility: hidden;

  iframe,
  object,
  embed {
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${props =>
    getModifier(props, 'isPlaying') &&
    `
      opacity: 1;
      transform: scale(1);
      visibility: visible;
  `};
`;

export default Iframe;
