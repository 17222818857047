// Import Dependencies
import { css } from 'styled-components';

// Import Globals
import * as vars from './vars';
import typography from './typography';

// Mixin
const linkMixin = css`
  &,
  &:link,
  &:visited {
    ${typography.body};
    -webkit-appearance: none;
    border-radius: 0;
    box-shadow: 0 -1px 0 0 ${vars.colors.blue500} inset, 0 -1px 0 0 transparent inset;
    color: ${vars.colors.blue500};
    font-weight: ${vars.fontWeight.normal};
    padding-bottom: 0;
    text-decoration: none;
    transition: box-shadow ${vars.global.transitionSpeed} ${vars.global.easing} 0s;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 -1px 0 0 transparent inset, 0 -3px 0 0 transparent inset;
  }
`;

export default linkMixin;
