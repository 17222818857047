// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const Price = styled.p`
  ${typography.heading1};
  color: ${vars.colors.grey500};
  display: flex;
  font-size: ${vars.fontSize.xxLarge};
  justify-content: center;
  margin-bottom: ${vars.spacing.midLarge};
  margin-top: ${vars.spacing.medium};

  ${vars.breakpoint.medium`
    font-size: ${vars.fontSize.xxxxLarge};
    margin-bottom: ${vars.spacing.xxSmall};
  `}

  span {
    font-size: ${vars.fontSize.small};
    margin-right: 2px;
    position: relative;
    top: -2px;

    ${vars.breakpoint.medium`
      font-size: ${vars.fontSize.large};
    `};
  }
`;

export default Price;
