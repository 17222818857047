// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledForm from '../../styles/components/Form';

const Legend = ({ text }) => <StyledForm.Legend>{text}</StyledForm.Legend>;

Legend.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Legend;
