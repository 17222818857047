// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const ArrowRight = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M72.2 227.8h301.9l-95.4-95.4c-8.7-8.7-8.7-22.7 0-31.4s22.7-8.7 31.4 0l133.3 133.3c8.7 8.7 8.7 22.7 0 31.4L310.2 399c-4.3 4.3-10 6.5-15.7 6.5s-11.4-2.2-15.7-6.5c-8.7-8.7-8.7-22.7 0-31.4l95.4-95.4h-302C60 272.2 50 262.3 50 250c0-12.3 10-22.2 22.2-22.2z" id="Layer_18" />
  </Icon>
);

export default ArrowRight;
