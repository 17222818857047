// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import ImageLoader from '../ImageLoader';
import Video from '../Video';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';

const ImageVideoBlock = ({ imageVideoModule, storybook }) => {
  const { toggleVideo, hasDarkBackground, caption, image, youtubeId } = imageVideoModule[0];
  const imageUrl = image && image[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${image[0].url}`;

  return (
    <Section modifiers={{ dots: true, dark: hasDarkBackground, dotsWhite: hasDarkBackground }}>
      <Container>
        <Grid>
          <Grid.Item modifiers={{ colsMedium: 8, offsetMedium: 2 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              {toggleVideo ? (
                <Video poster={imageUrl} videoCode={youtubeId} caption={caption} />
              ) : (
                <ImageLoader caption={caption} imageUrl={imageUrl} />
              )}
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
};

ImageVideoBlock.propTypes = {
  imageVideoModule: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  storybook: PropTypes.bool,
};

ImageVideoBlock.defaultProps = {
  storybook: false,
};

export default ImageVideoBlock;
