// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import ImageLoader from '../ImageLoader';
import Title from '../Title';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';
import Link from '../../styles/components/Link';
import Button from '../../styles/components/Button';

const Hero = ({
  heroHeading,
  heroDescription,
  heroImage,
  storybook,
  simple,
  ctaText,
  ctaInternalEntry,
  ctaSwitch,
  ctaExternalUrl,
}) => {
  if (simple) {
    return (
      <Section modifiers={{ dark: true, center: true, offsetTop: true }}>
        <Container>
          <Grid>
            <Grid.Item modifiers={{ colsMedium: 6, offsetMedium: 3 }}>
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                <Title
                  type="h2"
                  styleType="h1"
                  modifiers={{ white: true, largeMargin: !!heroDescription, noMargin: !heroDescription }}
                >
                  {heroHeading}
                </Title>
                {heroDescription && (
                  <Title type="h5" styleType="h3" modifiers={{ noMargin: true, white: true }}>
                    {heroDescription}
                  </Title>
                )}
              </Grid.Inner>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }

  const imageUrl = heroImage && heroImage[0] && `${storybook ? '' : process.env.GATSBY_CMS_DOMAIN}${heroImage[0].url}`;
  const internalUrl = ctaInternalEntry && ctaInternalEntry[0] && `/${ctaInternalEntry[0].uri}`;

  return (
    <Section modifiers={{ dark: true, dotsHero: true }}>
      <Container>
        {imageUrl && (
          <Section.Image>
            <ImageLoader modifiers={{ noAspectRatio: true }} imageUrl={imageUrl} />
          </Section.Image>
        )}
        <Grid>
          <Grid.Item modifiers={{ colsMedium: 4, offsetMedium: 3 }}>
            <Grid.Inner modifiers={{ noBottomMargin: true }}>
              <Title type="h2" styleType="h1" modifiers={{ white: true, largeMargin: true }}>
                {heroHeading}
              </Title>
              {heroDescription && (
                <Title type="h5" styleType="h5" modifiers={{ grey: true, noMargin: true }}>
                  {heroDescription}
                </Title>
              )}
              {ctaText && (ctaExternalUrl || ctaInternalEntry) && (
                <div style={{ marginTop: '50px' }}>
                  <Link target={ctaSwitch && '_blank'} href={ctaSwitch ? ctaExternalUrl : internalUrl}>
                    <Button>{ctaText}</Button>
                  </Link>
                </div>
              )}
            </Grid.Inner>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
};

Hero.propTypes = {
  heroHeading: PropTypes.string.isRequired,
  heroDescription: PropTypes.string,
  heroImage: PropTypes.arrayOf(PropTypes.shape({})),
  storybook: PropTypes.bool,
  simple: PropTypes.bool,
  ctaText: PropTypes.string,
  ctaInternalEntry: PropTypes.arrayOf(PropTypes.shape({})),
  ctaSwitch: PropTypes.bool,
  ctaExternalUrl: PropTypes.string,
};

Hero.defaultProps = {
  storybook: false,
  heroDescription: null,
  heroImage: null,
  simple: false,
  ctaText: null,
  ctaInternalEntry: null,
  ctaSwitch: null,
  ctaExternalUrl: null,
};

export default Hero;
