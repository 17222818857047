// Import Dependencies
import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
// import * as Yup from 'yup';

// Import Components
import Alert from '../Alert';
import Button from '../Button';
import Link from '../Link';
import Field from '../Field';
import Notification from '../Notification';

// Import Styled Components
import StyledForm from '../../styles/components/Form';
import { isObjectEmpty } from '../../utils';

class FormRender extends PureComponent {
  // Handle form submit
  handleSubmit = values => {
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit(values);
    }
  };

  // Handle secondary button click
  handleSecondarySubmit = values => {
    const { onSecondarySubmit } = this.props;

    if (onSecondarySubmit) {
      onSecondarySubmit(values);
    }
  };

  handleValidation = () => {
    const { validationSchema } = this.props;
    // can do custom things here
    return validationSchema;
  };

  render() {
    const {
      alwaysEnableButton,
      errorMessage,
      successMessage,
      isProcessing,
      fields,
      legend,
      onSubmit,
      onChange,
      children,
      submitLabel,
      noSubmit,
      disableSubmit,
      secondarySubmitLabel,
      onSecondarySubmit,
      formData,
      withButtonBorderTop,
      validationSchema,
      action,
      fullWidth,
      enableReinitialize,
      ...props
    } = this.props;

    return (
      <Formik
        initialValues={formData}
        enableReinitialize={enableReinitialize}
        validationSchema={!isObjectEmpty(validationSchema) && this.handleValidation}
        validateOnChange
        onSubmit={this.handleSubmit}
        render={formikProps => {
          const isButtonDisabled = alwaysEnableButton
            ? false
            : disableSubmit || isProcessing || (!isObjectEmpty(formikProps.touched) && !formikProps.isValid);

          return (
            <StyledForm modifiers={{ fullWidth }}>
              {successMessage && <Alert modifiers={{ success: true }}>{successMessage}</Alert>}
              <Form action={action && action} onSubmit={!action && formikProps.handleSubmit} {...props}>
                {legend && <StyledForm.Legend>{legend}</StyledForm.Legend>}
                {fields && fields.length && (
                  <StyledForm.Fields modifiers={{ fullWidth }}>
                    {fields.map(
                      field =>
                        field && (
                          <Field
                            key={field.id}
                            {...field}
                            onChange={value => {
                              if (typeof onChange === 'function') {
                                onChange(value);
                              }
                              formikProps.handleChange(value);
                            }}
                          />
                        ),
                    )}
                  </StyledForm.Fields>
                )}

                {errorMessage && (
                  <p>
                    <Notification modifiers={{ error: true, spacingTop: true }}>{errorMessage}</Notification>
                  </p>
                )}

                {children && <div style={{ marginBottom: '20px' }}>{children}</div>}

                <StyledForm.Actions modifier={{ largeSpacingTop: true, borderTop: withButtonBorderTop }}>
                  {!noSubmit && (
                    <Button
                      type="submit"
                      disabled={isButtonDisabled}
                      modifiers={{
                        disabled: alwaysEnableButton ? false : disableSubmit,
                        fullWidthMobile: true,
                        isProcessing,
                      }}
                    >
                      {submitLabel}
                    </Button>
                  )}
                  {onSecondarySubmit && (
                    <StyledForm.Action>
                      <Link
                        type="submit"
                        disabled={isButtonDisabled}
                        modifiers={{
                          secondary: true,
                          fullWidthMobile: true,
                          disabled: alwaysEnableButton ? false : disableSubmit,
                          isProcessing,
                        }}
                        onClick={() => this.handleSecondarySubmit(formikProps.values)}
                      >
                        {secondarySubmitLabel}
                      </Link>
                    </StyledForm.Action>
                  )}
                </StyledForm.Actions>
              </Form>
            </StyledForm>
          );
        }}
      />
    );
  }
}

FormRender.propTypes = {
  alwaysEnableButton: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  successMessage: PropTypes.string,
  errors: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fixedActions: PropTypes.bool,
  action: PropTypes.string,
  formData: PropTypes.shape({}),
  isProcessing: PropTypes.bool,
  withButtonBorderTop: PropTypes.bool,
  legend: PropTypes.string,
  noSubmit: PropTypes.bool,
  onSecondarySubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  secondarySubmitLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.shape({}),
  validationSchema: PropTypes.shape({}),
  onChange: PropTypes.func,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
};

FormRender.defaultProps = {
  alwaysEnableButton: false,
  disableSubmit: false,
  withButtonBorderTop: false,
  errorMessage: null,
  successMessage: null,
  errors: null,
  action: null,
  fixedActions: false,
  formData: null,
  isProcessing: false,
  legend: null,
  noSubmit: false,
  onSecondarySubmit: undefined,
  onSubmit: null,
  secondarySubmitLabel: null,
  submitLabel: 'Submit',
  initialValues: {},
  validationSchema: {},
  onChange: undefined,
  children: null,
  fullWidth: false,
  enableReinitialize: false,
};

export default FormRender;
