// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import typography from '../../global/typography';

// Import Utilities
import getModifier from '../../../utils/getModifier';
import { hexToRgba } from '../../global/utils';

const Title = styled.h3`
  ${typography.headingBase};
  margin-bottom: ${vars.spacing.base};

  /* Modifier: H1 */
  ${props =>
    getModifier(props, 'type') === 'h1' &&
    `
      ${typography.heading1};
    `};

  /* Modifier: H2 */
  ${props =>
    getModifier(props, 'type') === 'h2' &&
    `
      ${typography.heading2};
  `};

  /* Modifier: H3 */
  ${props =>
    getModifier(props, 'type') === 'h3' &&
    `
    ${typography.heading3};
  `};

  /* Modifier: H4 */
  ${props =>
    getModifier(props, 'type') === 'h4' &&
    `
    ${typography.heading4};
  `};

  /* Modifier: H5 */
  ${props =>
    getModifier(props, 'type') === 'h5' &&
    `
    ${typography.heading5};
  `};

  /* Modifier: H6 */
  ${props =>
    getModifier(props, 'type') === 'h6' &&
    `
    ${typography.heading6};
  `};

  /* Modifier: H7 */
  ${props =>
    getModifier(props, 'type') === 'h7' &&
    `
    ${typography.heading7};
  `};

  /* Modifier: Body */
  ${props =>
    getModifier(props, 'type') === 'body' &&
    `
    ${typography.body};
  `};

  /* Modifier: Flex */
  ${props =>
    getModifier(props, 'flex') &&
    `
      align-items: center;
      display: flex;

      & > a,
      & > button,
      & > div {
        margin-left: auto;
      }
  `};

  /* Modifier: Flex */
  ${props =>
    getModifier(props, 'flexMedium') &&
    vars.breakpoint.medium`
      align-items: center;
      display: flex;

      & > a,
      & > button,
      & > div {
        margin-left: auto;
      }
  `};

  /* Modifier: No Margin */
  ${props =>
    getModifier(props, 'noMargin') &&
    `
    margin-bottom: 0;
  `};

  /* Modifier: Large Margin */
  ${props =>
    getModifier(props, 'largeMarginMobile') &&
    `
      margin-bottom: ${vars.spacing.medium};
  `};

  ${props =>
    getModifier(props, 'largeMargin') &&
    vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.large};
  `};

  /* Modifier: Large Margin */
  ${props =>
    getModifier(props, 'xLargeMargin') &&
    vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.xLarge};
  `};

  /* Modifier: white */
  ${props =>
    getModifier(props, 'white') &&
    `
      color: ${vars.colors.white};
  `};

  /* Modifier: grey */
  ${props =>
    getModifier(props, 'grey') &&
    `
      color: ${hexToRgba(vars.colors.white, 0.6)};
  `};

  /* Modifier: dark grey */
  ${props =>
    getModifier(props, 'darkGrey') &&
    `
      color: ${hexToRgba(vars.colors.grey500, 0.3)};
  `};

  /* Modifier: dark grey */
    ${props =>
    getModifier(props, 'darkestGrey') &&
    `
      color: ${vars.colors.grey500};
  `};

  /* Modifier: Font bold */
  ${props =>
    getModifier(props, 'fontSemiBold') &&
    `
      font-weight: ${vars.fontWeight.semibold};
  `};

  ${props =>
    getModifier(props, 'smallMarginBottom') &&
    `
      margin-bottom: ${vars.spacing.xxSmall};
  `};

  ${props =>
    getModifier(props, 'minHeight') &&
    vars.breakpoint.medium`
      min-height: 60px;
  `};
`;

export default Title;
