// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';
import { toRems } from '../../global/utils';

const Link = styled.a`
  &,
  &:link,
  &:visited {
    ${typography.textLink};
    -webkit-appearance: none;
    border-radius: 0;
    box-shadow: 0 -2px 0 0 ${vars.colors.blue500} inset, 0 -3px 0 0 transparent inset;
    padding-bottom: ${toRems('3px')};
    text-decoration: none;
    transition: box-shadow ${vars.global.transitionSpeed} ${vars.global.easing} 0s;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 -2px 0 0 transparent inset, 0 -3px 0 0 transparent inset;
  }

  /* Modifier: Disabled */
  ${props =>
    getModifier(props, 'disabled') &&
    `
      &,
      &:link,
      &:visited,
      &:hover {
        box-shadow: 0 -2px 0 0 ${vars.colors.blue500} inset, 0 -3px 0 0 transparent inset;
        opacity: 0.5;
        pointer-events: none;
      }
  `};

  /* Modifier: wysiwyg inline link */
  ${props =>
    getModifier(props, 'inline') &&
    `
      &,
      &:link,
      &:visited {
        ${typography.body};
        box-shadow: 0 -1px 0 0 ${vars.colors.blue500} inset, 0 -1px 0 0 transparent inset;
        font-weight: ${vars.fontWeight.normal};
        padding-bottom: 0;
      }

      &:hover {
        box-shadow: 0 -1px 0 0 transparent inset, 0 -3px 0 0 transparent inset;
      }
  `};

  /* Modifier: white */
  ${props =>
    getModifier(props, 'white') &&
    `
      &,
      &:link,
      &:visited,
      &:hover {
        color: ${vars.colors.white};
        box-shadow: 0 -2px 0 0 ${vars.colors.white} inset, 0 -3px 0 0 transparent inset;
      }
  `};

  /* Modifier: Red */
  ${props =>
    getModifier(props, 'red') &&
    `
      &,
      &:link,
      &:visited
       {
        color: ${vars.colors.red500};
        box-shadow: 0 -2px 0 0 ${vars.colors.red500} inset, 0 -3px 0 0 transparent inset;
      }
  `};

  /* Modifier: hasIcon */
  ${props =>
    getModifier(props, 'hasIcon') &&
    `
      &,
      &:link,
      &:visited
       {
        margin-left: ${vars.spacing.medium};
        position: relative;

        svg {
          display: block
          fill: ${vars.colors.blue500};
          height: ${vars.spacing.small};
          left: -${vars.spacing.medium};
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: ${vars.spacing.small};
        }
      }
  `};

  ${props =>
    getModifier(props, 'largeSpacingLeft') &&
    `
      &,
      &:link,
      &:visited
      {
        margin-left: 50px;
       }
    `};
`;

export default Link;
