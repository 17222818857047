// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils';
import * as vars from '../../global/vars';

const Item = styled.li`
  align-items: center;
  break-inside: avoid;
  background-color: ${vars.colors.grey500};
  display: flex;
  font-size: ${vars.fontSize.small};
  padding: ${vars.spacing.xxSmall} ${vars.spacing.xSmall};
  transition: background-color ${vars.global.transitionSpeed} ${vars.global.easing} 0s,
    box-shadow ${vars.global.transitionSpeed} ${vars.global.easing} 0s;

  ${vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.xSmall};
    font-size: ${vars.fontSize.base};
  `}

  &:hover {
    background-color: ${vars.colors.grey400};

    ${vars.breakpoint.medium`
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.25);
    `}
  }

  ${props =>
    getModifier(props, 'hideOnMobile') &&
    `
      display: none;
  `};

  ${props =>
    getModifier(props, 'hideOnMobile') &&
    vars.breakpoint.medium`
      display: flex;
  `};
`;

export default Item;
