// Import Dependencies
import styled from 'styled-components';

import * as vars from '../../global/vars';

const Score = styled.span`
  flex: 0 0 auto;
  font-size: ${vars.fontSize.base};
  margin-left: auto;
  text-align: right;

  strong {
    font-size: ${vars.fontSize.large};
  }
`;

export default Score;
