// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const Label = styled.label`
  ${typography.smallText}
  color: ${vars.colors.grey500};
  font-weight: bold;
  left: 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

Label.Wrapper = Wrapper;

export default Label;
