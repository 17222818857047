// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Wrapper from './Wrapper';
import Caption from './Caption';
import InlineError from './InlineError';
import InlineTooltip from './InlineTooltip';
import Label from './Label';
import Icon from './Icon';

// Import Globals
import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const Field = styled.li`
  list-style: none;
  margin-bottom: ${vars.spacing.medium};
  position: relative;

  ${props =>
    getModifier(props, 'inline') &&
    `
    display: inline-block;
    vertical-align: top;
    width: 50%;
  `}

  ${props =>
    getModifier(props, 'inlineMobile') &&
    vars.breakpoint.medium`
      display: inline-block;
      width: 50%;
    `}

  ${props =>
    getModifier(props, 'paddingLeft') &&
    `
      padding-left: ${vars.spacing.small};
    `}

  ${props =>
    getModifier(props, 'paddingRight') &&
    `
      padding-right: ${vars.spacing.small};
    `}
`;

// Set elements
Field.Wrapper = Wrapper;
Field.Caption = Caption;
Field.InlineError = InlineError;
Field.Label = Label;
Field.InlineTooltip = InlineTooltip;
Field.Icon = Icon;

export default Field;
