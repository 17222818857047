// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import typography from '../../global/typography';
import getModifier from '../../../utils/getModifier';

const AccordionSection = styled.div`
  ${typography.body};
  color: ${vars.colors.white};
  border-top: 1px solid ${vars.colors.grey400};
  padding: ${vars.spacing.midLarge} 0;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid ${vars.colors.grey400};
  }

  ${props =>
    getModifier(props, 'darkText') &&
    `
    color: ${vars.colors.grey500};
  `}
`;

export default AccordionSection;
