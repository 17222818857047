// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledTitle from '../../styles/components/Title';

const Title = ({ type, styleType, children, text, modifiers, ...props }) => {
  const ElementType = StyledTitle.withComponent(type);

  return (
    <ElementType modifiers={{ type: styleType || type, ...modifiers }} {...props}>
      {children || text}
    </ElementType>
  );
};

Title.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.shape({}),
  text: PropTypes.string,
  type: PropTypes.string,
  styleType: PropTypes.string,
};

Title.defaultProps = {
  children: null,
  modifiers: {},
  text: null,
  type: 'h3',
  styleType: null,
};

export default Title;
