// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Accordion from '../Accordion';
import CardGalleryBlock from '../CardGallery';
import CardGrid from '../CardGrid';
import ContactFormBlock from '../ContactFormBlock';
import CtaBlock from '../CtaBlock';
import HighlightsBlock from '../HighlightsBlock';
import ImageVideoBlock from '../ImageVideoBlock';
import LeadersBlock from '../LeadersBlock';
import LogoBlock from '../LogoBlock';
import PlansBlock from '../PlansBlock';
import SplitContentBlock from '../SplitContentBlock';
import WysiwygBlock from '../WysiwygBlock';

const componentMap = {
  Craft_ContentBlockAccordion: Accordion,
  Craft_ContentBlockCardGallery: CardGalleryBlock,
  Craft_ContentBlockCardList: CardGrid,
  Craft_ContentBlockContactForm: ContactFormBlock,
  Craft_ContactBlockContactFormSingle: ContactFormBlock,
  Craft_ContentBlockCtaModule: CtaBlock,
  Craft_ContentBlockHighlights: HighlightsBlock,
  Craft_ContentBlockImageVideoModule: ImageVideoBlock,
  Craft_ContentBlockLogoGrid: LogoBlock,
  Craft_ContentBlockProductPricing: PlansBlock,
  Craft_ContentBlockSplitContent: SplitContentBlock,
  Craft_ContentBlockTwiLeaders: LeadersBlock,
  Craft_ContentBlockWysiwyg: WysiwygBlock,
};

const CmsBlock = ({ __typename: typename, ...props }) => {
  // Check that Component Exists
  if (!typename || !componentMap[typename]) {
    return false;
  }

  const Component = componentMap[typename];

  return <Component {...props} />;
};

CmsBlock.propTypes = {
  __typename: PropTypes.string.isRequired,
};

export default CmsBlock;
