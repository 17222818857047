// Import Dependencies
import styled from 'styled-components';

import * as vars from '../../global/vars';

const CountNumber = styled.span`
  font-size: ${vars.fontSize.small};
  font-weight: ${vars.fontWeight.bold};
  opacity: 0.3;
  margin-right: ${vars.spacing.base};
`;

export default CountNumber;
