// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

const ActionsMobile = styled.div`
  display: block;
  margin-top: ${vars.spacing.large};

  ${vars.breakpoint.medium`
    display: none;
  `};

  a {
    margin-bottom: ${vars.spacing.base};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default ActionsMobile;
