// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const InlineTooltip = styled.p`
  ${typography.smallText};
  color: ${vars.colors.grey400};
  font-weight: ${vars.fontWeight.medium};
  margin: ${vars.spacing.xSmall} 0 0;
  opacity: 0.5;
`;

export default InlineTooltip;
