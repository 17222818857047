// Import dependencies
import React, { PureComponent } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PropTypes from 'prop-types';
// Import Components
import HeaderLogo from '../HeaderLogo';
import NavMarketing from '../NavMarketing';
import NavMobileMenuButton from '../NavMobileMenuButton';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';

class HeaderMarketing extends PureComponent {
  state = {
    isMobileNavOpen: false,
    isOpen: false,
  };

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  toggleMobileNav = () => {
    const { isMobileNavOpen } = this.state;

    if (isMobileNavOpen) {
      enableBodyScroll(this.header);
    } else {
      disableBodyScroll(this.header);
    }

    this.setState(prevState => ({
      isMobileNavOpen: !prevState.isMobileNavOpen,
      isOpen: !prevState.isOpen,
    }));
  };

  setRef = el => {
    this.header = el;
  };

  render() {
    const { fixed, ...props } = this.props;
    const { isMobileNavOpen, isOpen } = this.state;

    return (
      <StyledHeader modifiers={{ fixed }} ref={this.setRef} {...props}>
        <a href="/">
          <HeaderLogo />
        </a>

        <NavMarketing modifiers={{ isMobileNavOpen }} {...props} />

        <NavMobileMenuButton openMobileNav={this.toggleMobileNav} modifiers={{ isOpen }} />
      </StyledHeader>
    );
  }
}

HeaderMarketing.propTypes = {
  fixed: PropTypes.bool,
};

HeaderMarketing.defaultProps = {
  fixed: false,
};

export default HeaderMarketing;
