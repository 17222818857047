// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const HighlightsTitle = styled.h3`
  ${typography.heading3};
  font-size: ${vars.fontSize.medium};
  margin-bottom: ${vars.spacing.base};

  ${vars.breakpoint.medium`
    margin-bottom: ${vars.spacing.large};
  `}
`;

const HighlightsOffset = styled.div`
  ${vars.breakpoint.medium`
    position: relative;
    top: 140px;
  `};

  ${props =>
    getModifier(props, 'useMargin') &&
    vars.breakpoint.medium` 
      margin-top: 140px;
      top: 0;
  `};
`;

HighlightsOffset.Title = HighlightsTitle;

export default HighlightsOffset;
