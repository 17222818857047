// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const Legend = styled.legend`
  ${typography.heading2}
  margin-bottom: ${vars.spacing.base};
`;

export default Legend;
