// Import Dependencies
import styled from 'styled-components';

// Import utils
import getModifier from '../../../utils/getModifier';

// Import Globals
import * as vars from '../../global/vars';

const ButtonList = styled.ul`
  margin-top: ${vars.spacing.medium};

  ${vars.breakpoint.small`
    align-items: center;
    display: flex;
    justify-content: center;
  `};

  ${props =>
    getModifier(props, 'paddingBottomMobile') &&
    `
      padding-bottom: ${vars.spacing.base};
    `}

  ${props =>
    getModifier(props, 'paddingBottomMobile') &&
    vars.breakpoint.small`
      padding-bottom: 0;
    `}

  li {
    margin-bottom: ${vars.spacing.base};

    ${vars.breakpoint.small`
      margin: ${vars.spacing.xSmall};
    `};

    ${vars.breakpoint.medium`
      flex: 1 0 auto;
      max-width: 250px;
      width: 100%;
    `};
  }
`;

export default ButtonList;
