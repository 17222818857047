// Import Dependencies
import { createGlobalStyle } from 'styled-components';

// Import base styles
import normalize from './normalize';
import base from './base';
import print from './print';
import font from './font';
import plugins from './plugins';

// Global CSS Styles
const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${font}
  ${base}
  ${print}
  ${plugins}

`;

export default GlobalStyle;
