// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils';

// Import vars
import * as vars from '../../global/vars';
import typography from '../../global/typography';
import { toRems } from '../../global/utils';

const NavItemMarketing = styled.li`
  padding-bottom: ${vars.spacing.base};
  transition: opacity ${vars.global.transitionSpeed} linear;

  &:hover {
    opacity: 1;
  }

  ${vars.breakpoint.medium`
    padding: 0 ${vars.spacing.small};
  `};

  ${vars.breakpoint.large`
    padding: 0 ${vars.spacing.midLarge};
  `};

  &:last-child {
    padding-right: 0;

    ${vars.breakpoint.medium`
      padding-left: ${vars.spacing.base};
    `};

    ${vars.breakpoint.large`
      padding-left: ${toRems('70px')};
    `};
  }

  &:first-child {
    ${vars.breakpoint.medium`
      padding-left: 0;
    `};
  }

  a {
    &,
    &:link,
    &:visited {
      ${typography.textLink};
      color: ${vars.colors.white};
      font-size: ${vars.fontSize.xxLarge};
      font-weight: ${vars.fontWeight.bold};
      padding: ${vars.spacing.xxSmall} 0;
      position: relative;
      text-align: left;
      text-decoration: none;

      ${vars.breakpoint.medium`
        ${typography.textLink};
        color: ${vars.colors.white};
        font-weight: ${vars.fontWeight.medium};
        text-align: center;
      `};
    }

    &:after {
      background-color: ${vars.colors.blue500};
      bottom: 0;
      content: '';
      display: block;
      height: 5px;
      opacity: 0;
      left: 0;
      margin-bottom: -${toRems('8px')};
      position: absolute;
      right: 0;
    }

    &:hover,
    &:active {
      color: ${vars.colors.white};

      &:after {
        opacity: 1;
      }
    }

    ${vars.breakpoint.medium`
      border-bottom: solid 3px transparent;
      color: ${vars.colors.white};
      text-align: left;

      svg {
        display: none;
      }
    `};
  }

  /* Modifier: isActive */
  ${props =>
    getModifier(props, 'isActive') &&
    `
      a {
        color: ${vars.colors.white};

        &:after {
          opacity: 1;
        }
      }
  `};

  /* Modifier: hideOnMobile */
  ${props =>
    getModifier(props, 'hideOnMobile') &&
    `
      display: none;
  `};

  ${props =>
    getModifier(props, 'hideOnMobile') &&
    vars.breakpoint.medium`
      display: block;
      margin-right: ${vars.spacing.large};
  `};
`;

export default NavItemMarketing;
