// Import Dependencies
import styled from 'styled-components';

// Import Elements

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

import { getModifier } from '../../../utils';

const FieldHint = styled.div`
  position: relative;
  border-radius: 50%;
  width: 18px;
  height: 18px;

  cursor: pointer;
  transition: all ${vars.global.transitionSpeed} ${vars.global.easing};
  z-index: 2;

  &:hover {
    background-color: ${vars.colors.blue200};
  }

  ${({ open }) =>
    open &&
    `
    background-color: ${vars.colors.blue200};
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    background-color: transparent;
    height: auto;
    width: auto;

    &:hover {
      background-color: transparent;
    }
  `}

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    left: -3px;
    top: -3px;
    color: ${vars.colors.blue500};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin-left: ${vars.spacing.xxSmall};

  ${({ fullWidth }) =>
    fullWidth &&
    `
    display: block;
    margin: 0;
    width: auto;
  `}
`;

const Tooltip = styled.div`
  ${typography.smallText}
  background-color: #fff;
  border: solid 2px ${vars.colors.blue500};
  bottom: calc(100% - -10px);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.2);
  left: -3px;
  padding: 18px 16px;
  position: absolute;
  width: 250px;
  z-index: 9999;

  opacity: 0;
  visibility: none;
  pointer-events: none;
  transition: opacity ${vars.global.transitionSpeed} ${vars.global.easing};

  ${({ open }) =>
    open &&
    `
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
      display: block;
      margin: 0;
      width: auto;
    `}

  ${({ minWidth }) =>
    minWidth &&
    `
      min-width: 120px;
      max-width: 200px;
    `}

  ${props =>
    getModifier(props, 'value') &&
    `
      left: ${getModifier(props, 'value')}%;
    `}

  ${({ small }) =>
    small &&
    `
      width: 65px;
    `}

  &:after,
  &:before {
    top: 100%;
    left: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 3px;
    margin-left: -3px;
  }
  &:before {
    border-color: rgba(57, 114, 231, 0);
    border-top-color: ${vars.colors.blue500};
    border-width: 6px;
    margin-left: -6px;
  }

  ${({ yellow }) =>
    yellow &&
    `
    border-color: ${vars.colors.yellow500};

    &:before {
      border-top-color: ${vars.colors.yellow500};
    }
  `}
`;

FieldHint.Wrapper = Wrapper;
FieldHint.Tooltip = Tooltip;

export default FieldHint;
