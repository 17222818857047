// Import Dependencies
import styled from 'styled-components';

// Import elements
import Image from './Image';

// Import Utilities
import getModifier from '../../../utils/getModifier';

// Import Globals
import * as vars from '../../global/vars';

const Section = styled.section`
  display: block;
  padding: ${vars.spacing.large} 0;
  position: relative;
  width: 100%;
  z-index: 1;

  ${vars.breakpoint.medium`
    padding: 120px 0;
  `};

  ${props =>
    getModifier(props, 'largePadding') &&
    `
      padding: 80px 0 40px;
  `};

  ${props =>
    getModifier(props, 'largePadding') &&
    vars.breakpoint.medium`
      padding: 180px 0;
  `};

  ${props =>
    getModifier(props, 'smallPadding') &&
    `
      padding: 40px 0;
  `};

  ${props =>
    getModifier(props, 'smallPadding') &&
    vars.breakpoint.medium`
      padding: 60px 0;
  `};

  ${props =>
    getModifier(props, 'smallPaddingTop') &&
    vars.breakpoint.medium`
      padding-top: 45px;
  `};

  ${props =>
    getModifier(props, 'noPaddingTop') &&
    vars.breakpoint.medium`
    padding-top: 0;
  `};

  ${props =>
    getModifier(props, 'noBottomPadding') &&
    vars.breakpoint.medium`
    padding-bottom: 0;
  `};

  ${props =>
    getModifier(props, 'app') &&
    `
      padding: 40px 0;
  `};

  ${props =>
    getModifier(props, 'app') &&
    vars.breakpoint.medium`
    padding: 40px 0;
  `};

  ${props =>
    getModifier(props, 'noTopPaddingIfFirst') &&
    `
      &:first-child {
        margin-top: -${vars.spacing.base};
      }
  `};

  ${props =>
    getModifier(props, 'noTopPaddingIfFirst') &&
    vars.breakpoint.medium`
      &:first-child {
        margin-top: -${vars.spacing.xLarge};
      }
  `};

  ${props =>
    getModifier(props, 'offsetPadding') &&
    vars.breakpoint.medium`
      padding: 80px 100px 160px;
  `};

  ${props =>
    getModifier(props, 'dark') &&
    ` 
      background-color: ${vars.colors.grey500};
      color: ${vars.colors.white};
  `};

  ${props =>
    getModifier(props, 'white') &&
    ` 
      background-color: ${vars.colors.white};
      color: ${vars.colors.grey500};
  `};

  ${props =>
    getModifier(props, 'center') &&
    ` 
      text-align: center;
  `};

  ${props =>
    getModifier(props, 'dotsHero') &&
    ` 
      padding-bottom: 50px;
      padding-right: ${vars.components.headerCtaWidth};
      padding-top: 100px;

      &:after {
        background-image: url(${vars.svg.dot});
        background-size: 12px 12px;
        bottom: 0;
        content: '';
        opacity: 0.1;
        position: absolute;
        right: 0;
        top: 0;
        width: ${vars.components.headerCtaWidth};
      }
  `};

  ${props =>
    getModifier(props, 'dotsHero') &&
    vars.breakpoint.medium`
      padding-right: 0;
      
      &:after {
        width: ${vars.components.headerCtaWidthMedium};
      }
  `};

  ${props =>
    getModifier(props, 'dots') &&
    ` 
      &:before {
        background-image: url(${vars.svg.dot});
        background-size: 12px 12px;
        content: '';
        height: 100%;
        left: 50%;
        max-height: 56%;
        max-width: ${vars.grid.maxWidth};
        opacity: 0.1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: -1;
      }
  `};

  ${props =>
    getModifier(props, 'dotsFull') &&
    vars.breakpoint.medium` 
      height: 100%;
      padding-left: 50px;

      &:before {
        max-height: 100%;
        max-width: 100%;
        top: 50%;
      }
  `};

  ${props =>
    getModifier(props, 'dotsWhite') &&
    ` 
      &:before {
        background-image: url(${vars.svg.dotWhite});
        background-size: 12px 12px;
        opacity: 0.07;
      }
  `};

  ${props =>
    getModifier(props, 'dotsOuter') &&
    ` 
      &:before {
        bottom: ${vars.spacing.base};
        height: auto
        max-height: none;
        max-width: ${vars.grid.maxWidth};
        top: ${vars.spacing.base};
        transform: translate(-50%, 0);
      }
  `};

  ${props =>
    getModifier(props, 'dotsOuter') &&
    vars.breakpoint.medium`
      &:before {
        bottom: ${vars.spacing.large};
        top: ${vars.spacing.large};
      }
  `};

  ${props =>
    getModifier(props, 'dotsBottom') &&
    ` 
      &:before {
        max-height: 60%;
        max-width: ${vars.grid.maxWidth};
        top: 70%;
      }
  `};

  ${props =>
    getModifier(props, 'dotsBottom') &&
    vars.breakpoint.medium` 
      &:before {
        max-height: 50%;
        max-width: ${vars.grid.maxWidth};
        top: 65%;
      }
  `};

  ${props =>
    getModifier(props, 'dotsTopBottom') &&
    vars.breakpoint.medium` 
      &:before {
        bottom: 0;
        max-height: 84%;
        left: 10%;
        top: 50%;
        width: 80%;
      }
    `};

  ${props =>
    getModifier(props, 'dotsLeft') &&
    vars.breakpoint.medium` 
    &:before {
      background-image: url(${vars.svg.dot});
      background-size: 12px 12px;
      bottom: 90px;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      top: 90px;
      width: 33.33%;
      z-index: 1;
    }
  `};

  ${props =>
    getModifier(props, 'dotsRight') &&
    vars.breakpoint.medium` 
    &:before {
      background-image: url(${vars.svg.dot});
      background-size: 12px 12px;
      bottom: 90px;
      content: '';
      right: 0;
      opacity: 0.1;
      position: absolute;
      top: 90px;
      width: 33.33%;
      z-index: 1;
    }
  `};

  ${props =>
    getModifier(props, 'smallPaddingBottom') &&
    ` 
      padding-bottom: 0;
    `};

  ${props =>
    getModifier(props, 'smallPaddingBottom') &&
    vars.breakpoint.medium` 
      padding-bottom: ${vars.spacing.medium};
    `};

  ${props =>
    getModifier(props, 'offsetTop') &&
    ` 
     padding-top: 80px;
  `};

  ${props =>
    getModifier(props, 'offsetTop') &&
    vars.breakpoint.medium`
    margin-top: 0;
  `};

  ${props =>
    getModifier(props, 'gradient') &&
    vars.breakpoint.medium` 
    overflow: hidden;

    &:before {
      background: linear-gradient(25deg, ${vars.colors.grey500} 50%, ${vars.colors.grey400});
      bottom: 0%;
      content: '';
      display: block;
      left: 0%;
      position: absolute;
      right: 0;
      top: 30%;
      transform-origin: right top;
      transform: rotate(-25deg);
      z-index: -1;
     }
  `};
`;

Section.Image = Image;

export default Section;
