// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledHeader from '../../styles/components/HeaderMarketing';

const NavMobileMenuButton = ({ openMobileNav, modifiers, ...props }) => (
  <StyledHeader.NavMobileMenuButton onClick={openMobileNav} modifiers={modifiers} {...props}>
    <span>Menu</span>
    <StyledHeader.NavMobileMenuButtonMiddleLine />
  </StyledHeader.NavMobileMenuButton>
);

NavMobileMenuButton.propTypes = {
  openMobileNav: PropTypes.func.isRequired,
  modifiers: PropTypes.shape({}),
};

NavMobileMenuButton.defaultProps = {
  modifiers: {},
};

export default NavMobileMenuButton;
