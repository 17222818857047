/**
 * Global variables
 *
 * Usage:
 * import * as vars from '../global/vars'
 * ...
 * border-radius: ${vars.global.radius};
 *
 */

import { css } from 'styled-components';
import * as utils from './utils';

// Colors
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey500: '#31323C',
  grey400: '#3F414D',
  grey350: '#52545f',
  grey300: '#EAEAEB',
  grey200: '#F8F8F9',
  blue100: 'rgba(57, 114, 231, 0.2)',
  blue200: 'rgb(224, 233, 252)',
  blue400: '#00B1FF',
  blue500: '#3972E7',
  green500: '#1F8A0A',
  green400: '#BBDBB5',
  red500: '#EC1212',
  red400: '#F58788',
  yellow600: '#D8A227',
  yellow500: '#E7B239',
};

export const gradients = {
  blue: `linear-gradient(
      90deg,
      #3972E7 0%,
      #3972E7 50%,
      #00B1FF 75%,
      #3972E7 100%
    )`,
  blueBar: `linear-gradient(
      90deg,
      ${utils.hexToRgba(colors.blue400, 0.3)} 0%,
      ${colors.blue400} 100%
    )`,
  blueBarDimensions: `linear-gradient(
      90deg,
      ${colors.blue400} 0%,
      ${colors.blue500} 100%
    )`,
  yellowBarDimensions: `linear-gradient(
      90deg,
      ${colors.yellow500} 0%,
      ${colors.yellow600} 100%
    )`,
  grey: `linear-gradient(
      90deg,
      #3F414D 0%,
      #3F414D 50%,
      #31323C 75%,
      #3F414D 100%
    )`,
};

export const baseFontColor = colors.grey500;
export const baseFontSize = '16px';
export const baseFontFamily = 'Gilmer, sans-serif';

export const spacing = {
  xxSmall: '5px',
  xSmall: '10px',
  small: '15px',
  base: '20px',
  medium: '25px',
  midLarge: '30px',
  large: '40px',
  xLarge: '80px',
};

export const grid = {
  maxWidth: '1320px',
  maxWidthSmall: '1030px',
  maxWidthNarrow: '665px',
  columns: 12,
  gutter: '20px',
  gutterValue: 20,
};

export const fontSize = {
  xxxxLarge: utils.toRems('64px'),
  xxxLarge: utils.toRems('40px'),
  xxLarge: utils.toRems('28px'),
  xLarge: utils.toRems('24px'),
  large: utils.toRems('20px'),
  medium: utils.toRems('18px'),
  base: utils.toRems('16px'),
  small: utils.toRems('14px'),
  xSmall: utils.toRems('12px'),
  xxSmall: utils.toRems('10px'),
  xxxSmall: utils.toRems('8px'),
};

export const fontWeight = {
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400,
};

export const easing = {
  enter: 'cubic-bezier(0, 0.5, 0.5, 1)',
  exit: 'cubic-bezier(0.5, 0, 0.5, 1)',
  move: 'cubic-bezier(0.5, 0, 0, 1)',
};

export const global = {
  radius: '4px',
  transitionSpeed: '250ms',
  transitionSpeedLong: '2s',
  easing: easing.move,
  shadow: '0 2px 5px 0 rgba(0,0,0,0.26)',
  tapHighlightColor: 'rgba(255, 255, 255, 0);',
};

export const layer = {
  progress: 10000,
  modal: 9000,
  nav: 8000,
  header: 7000,
  tooltip: 6000,
};

export const bp = {
  small: '600px',
  medium: '900px',
  large: '1200px',
  xLarge: '1400px',
  xxLarge: '1600px',
  xxxLarge: '2000px',
  xxxxLarge: '2300px',
};

export const components = {
  headerHeightMobile: '60px',
  headerHeightDesktop: '80px',
  sidebarHeightSmall: '67px',
  sidebarWidthMedium: '88px',
  sidebarWidthLarge: '200px',
  sidebarStepperWidthMedium: '158px',
  sidebarStepperWidthLarge: '270px',
  summaryBarWidthMedium: '232px',
  summaryBarWidthLarge: '309px',
  stepperMarginWidth: '70px',
  titleBarHeightMobile: '60px',
  titleBarHeightDesktop: '76px',
  headerCtaWidth: '90px',
  headerCtaWidthMedium: '187px',
  sectionImageWidth: '191px',
  sectionImageOffsetBottom: '196px',
  cardHeight: '500px',
  cardHeightMobile: '326px',
};

export const svg = {
  curve:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MDAgNTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDAgNTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzM5NzJFNztzdHJva2Utd2lkdGg6MTI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQo8L3N0eWxlPgo8cGF0aCBpZD0iTGF5ZXJfMTlfMl8iIGNsYXNzPSJzdDAiIGQ9Ik01MTAsMzMxaC0yMGMtMzQuNiwwLTU2LjUtMTIuNy02NS42LTM4LjFDNDAwLjEsMjIwLjksMzMxLjIsMTY5LDI1MCwxNjkKCWMtODEuMSwwLTE1MCw1MS44LTE3NC4zLDEyMy43QzY2LjUsMzE4LjIsNDQuNiwzMzEsMTAsMzMxaC0yMCIvPgo8L3N2Zz4K',
  dot:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHJlY3QgeD0iNC41IiB5PSI0LjUiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiLz48L3N2Zz4=', //eslint-disable-line
  dotWhite:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHJlY3QgeD0iNC41IiB5PSI0LjUiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=', //eslint-disable-line
};

export const breakpoint = {
  xsmall: (...args) => css`
    @media (max-width: ${bp.small}) {
      ${css(...args)};
    }
  `,
  small: (...args) => css`
    @media (min-width: ${bp.small}) {
      ${css(...args)};
    }
  `,
  medium: (...args) => css`
    @media (min-width: ${bp.medium}) {
      ${css(...args)};
    }
  `,
  large: (...args) => css`
    @media (min-width: ${bp.large}) {
      ${css(...args)};
    }
  `,
  xLarge: (...args) => css`
    @media (min-width: ${bp.xLarge}) {
      ${css(...args)};
    }
  `,
  xxLarge: (...args) => css`
    @media (min-width: ${bp.xxLarge}) {
      ${css(...args)};
    }
  `,
  xxxLarge: (...args) => css`
    @media (min-width: ${bp.xxxLarge}) {
      ${css(...args)};
    }
  `,
  xxxxLarge: (...args) => css`
    @media (min-width: ${bp.xxxxLarge}) {
      ${css(...args)};
    }
  `,
};
