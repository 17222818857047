// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

const ActionsDesktop = styled.div`
  display: none;

  ${vars.breakpoint.medium`
    align-self: stretch;
    display: flex;
  `};
`;

export default ActionsDesktop;
