// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';
import { toRems } from '../../global/utils';
import getModifier from '../../../utils/getModifier';

import Title from './Title';
import Header from './Header';
import Wrapper from './Wrapper';
import Actions from './Actions';

const Card = styled.div`
  background-color: ${vars.colors.white};
  color: ${vars.colors.black};
  font-size: ${vars.fontSize.small};
  min-height: ${vars.components.cardHeightMobile};
  min-width: ${toRems('210px')};
  padding: ${vars.spacing.medium} ${vars.spacing.base};
  text-align: left;
  width: 100%;
  z-index: 1;

  ${vars.breakpoint.small`
    height: 100%;
  `};

  ${vars.breakpoint.medium`
    height: 100%;
    max-width: 100%;
    min-height: ${vars.components.cardHeight};
    padding: ${toRems('50px')};
    width: ${toRems('400px')};
  `};

  ${props =>
    getModifier(props, 'dark') &&
    `
      background-color: ${vars.colors.grey400};
      color: ${vars.colors.white};
  `};

  ${props =>
    getModifier(props, 'noBorder') &&
    `
      border: 0;
  `};

  ${props =>
    getModifier(props, 'video') &&
    `
      min-height: 0;
      flex: 1;
    `};

  ${props =>
    getModifier(props, 'video') &&
    vars.breakpoint.medium`
      min-height: 0;
      text-align: center;
      width: 100%;
    `};

  ${props =>
    getModifier(props, 'image') &&
    `
      flex: 1;
      min-height: 0;
    `};

  ${props =>
    getModifier(props, 'image') &&
    vars.breakpoint.medium`
      min-height: 0;
      text-align: center;
      width: 100%;
    `};

  ${props =>
    getModifier(props, 'fullWidth') &&
    `
        width: 100%;
    `};

  ${props =>
    getModifier(props, 'fullWidth') &&
    vars.breakpoint.medium`
        width: 100%;
    `};

  ${props =>
    getModifier(props, 'sliderCard') &&
    `
      width: ${toRems('210px')};
    `};

  ${props =>
    getModifier(props, 'sliderCard') &&
    vars.breakpoint.medium`
        width: ${toRems('400px')};
    `};
`;

Card.Title = Title;
Card.Header = Header;
Card.Wrapper = Wrapper;
Card.Actions = Actions;

export default Card;
