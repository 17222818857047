/* eslint-disable react/prop-types */
// Import dependencies
import React from 'react';
import { Field } from 'formik';

// import PropTypes from 'prop-types';

// Import Components
import StyledField from '../../styles/components/Field';
import StyledRadioButtonGroup from '../../styles/components/RadioButtonGroup';

const RadioButtonGroup = ({ name, caption, children }) => {
  return (
    <Field name={name}>
      {({ form: { errors, touched } }) => {
        const hasError = errors[name] && touched[name];
        return (
          <StyledField.Wrapper>
            <StyledRadioButtonGroup>
              {React.Children.map(children, child =>
                React.cloneElement(child, {
                  key: child.props.value,
                  name,
                  hasError,
                }),
              )}
            </StyledRadioButtonGroup>
            {hasError && <StyledField.InlineError>{errors[name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </Field>
  );
};

export default RadioButtonGroup;
