// Import Dependencies
import React, { useState } from 'react';

import { Field } from 'formik';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PropTypes from 'prop-types';

import { formatDate, parseDate } from 'react-day-picker/moment';

// Import Styled Components
import StyledTextInput from '../../styles/components/TextInput';
import StyledField from '../../styles/components/Field';
import CalendarIcon from '../Icons/Calendar';

import DayPickerWrapper from '../../styles/components/DateField';

const DateField = ({ name, caption, onChange, disabled }) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Field name={name}>
      {({ field, form: { setFieldValue, touched, errors, values } }) => {
        const showError = errors[field.name] && touched[field.name];

        return (
          <StyledField.Wrapper>
            <StyledTextInput hasFocus={hasFocus} hasError={showError}>
              <DayPickerWrapper>
                <DayPickerInput
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format="DD/MM/YYYY"
                  inputProps={{
                    placeholder: `${formatDate(new Date(), 'DD/MM/YYYY')}`,
                    id: field.name,
                    readOnly: true,
                    disabled,
                    onFocus: () => setHasFocus(true),
                    onBlur: e => {
                      field.onBlur(e);
                      setHasFocus(false);
                    },
                  }}
                  dayPickerProps={{
                    firstDayOfWeek: 1,
                    showOutsideDays: true,
                  }}
                  onDayChange={day => {
                    if (onChange) onChange({ ...values, [name]: formatDate(day, 'DD/MM/YYYY') });
                    setFieldValue(field.name, formatDate(day, 'DD/MM/YYYY'));
                  }}
                  value={field.value}
                />
              </DayPickerWrapper>
              <StyledField.Icon>
                <CalendarIcon />
              </StyledField.Icon>
            </StyledTextInput>
            {showError && <StyledField.InlineError htmlFor={field.name}>{errors[field.name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption htmlFor={field.name}>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </Field>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  caption: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DateField.defaultProps = {
  caption: '',
  onChange: null,
  disabled: false,
};

export default DateField;
