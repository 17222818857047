// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';

const AccordionSectionContent = styled.div`
  overflow: hidden;
  text-align: left;
  transition: height ${vars.global.transitionSpeed} ${vars.easing.exit};

  ${vars.breakpoint.medium`
    margin-right: 0;
    width: 80%;
  `}

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  > div {
    padding: ${vars.spacing.base} ${vars.spacing.midLarge} 0 0;
  }
`;

export default AccordionSectionContent;
