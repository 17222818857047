// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledImage from '../../styles/components/Image';

export class ImageLoader extends PureComponent {
  state = {
    isLoaded: false,
  };

  componentDidMount() {
    const { imageUrl } = this.props;

    this.img = new Image();
    this.img.src = imageUrl;
    this.img.onload = this.handleImageLoad;
  }

  componentWillUnmount() {
    this.img.src = null;
    this.img.onload = null;
  }

  handleImageLoad = () => {
    this.setState({
      isLoaded: true,
    });
  };

  renderImage = () => {
    const { imageUrl, modifiers } = this.props;
    const { isLoaded } = this.state;

    return (
      <StyledImage modifiers={modifiers}>
        <StyledImage.Background
          modifiers={{ isLoaded, ...modifiers }}
          style={{ backgroundImage: `url(${isLoaded && imageUrl})` }}
        >
          <img src={imageUrl} alt="" />
        </StyledImage.Background>
      </StyledImage>
    );
  };

  render() {
    const { caption } = this.props;

    if (!caption) {
      return this.renderImage();
    }

    return (
      <div>
        {this.renderImage()}
        {caption && <StyledImage.Caption>{caption}</StyledImage.Caption>}
      </div>
    );
  }
}

ImageLoader.propTypes = {
  caption: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  modifiers: PropTypes.shape({}).isRequired,
};

ImageLoader.defaultProps = {
  caption: null,
};

export default ImageLoader;
