// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledTextInput from '../../styles/components/TextInput';
import StyledField from '../../styles/components/Field';
import DownIcon from '../Icons/Down';

const Select = ({ options, value, id, optional, error, defaultValue, ...props }) => (
  <StyledTextInput id={id} htmlFor={`${id}-select`} hasError={!!error} {...props}>
    <select id={id} name={id} value={value} {...props}>
      {options &&
        options.map(option => (
          <option id={option.id} key={option.id} value={option.value} selected={option.selected}>
            {option.label || option.value}
          </option>
        ))}
    </select>
    <StyledField.Icon>
      <DownIcon />
    </StyledField.Icon>
  </StyledTextInput>
);

Select.propTypes = {
  defaultValue: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.shape({})),
};

Select.defaultProps = {
  defaultValue: '',
  value: undefined,
  optional: false,
  error: null,
};

export default Select;
