// Import Dependencies
import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledCheckbox from '../../styles/components/Checkbox';
import StyledField from '../../styles/components/Field';

const CheckboxField = ({ name, label, caption }) => {
  return (
    <Field name={name}>
      {({ field, form: { errors, touched } }) => {
        const showError = errors[name] && touched[name];
        return (
          <StyledField.Wrapper>
            <StyledCheckbox>
              <StyledCheckbox.Input type="checkbox" id={name} {...field} checked={field.value} />
              <StyledCheckbox.Label htmlFor={name}>{label}</StyledCheckbox.Label>
            </StyledCheckbox>
            {showError && <StyledField.InlineError>{errors[name]}</StyledField.InlineError>}
            {caption && <StyledField.Caption>{caption}</StyledField.Caption>}
          </StyledField.Wrapper>
        );
      }}
    </Field>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  caption: PropTypes.string,
  hint: PropTypes.string,
};

CheckboxField.defaultProps = {
  value: null,
  fieldLabel: null,
  caption: null,
  hint: null,
  error: null,
};

export default CheckboxField;
