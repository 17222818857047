// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const LoginLogout = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M250 430c-41.9 0-83.8-15.9-115.7-47.8-63.8-63.8-63.8-167.6 0-231.4 6.4-6.4 16.7-6.4 23.1 0 6.4 6.4 6.4 16.7 0 23.1-51 51-51 134.1 0 185.1s134.1 51 185.1 0 51.1-134.1 0-185.1c-6.4-6.4-6.4-16.8 0-23.1 6.4-6.4 16.7-6.4 23.1 0 63.8 63.8 63.8 167.6 0 231.4C333.8 414.1 291.9 430 250 430z" />
    <path d="M250.1 266.4c-9 0-16.4-7.3-16.4-16.4V86.4c0-9 7.3-16.4 16.4-16.4s16.4 7.3 16.4 16.4V250c-.1 9.1-7.4 16.4-16.4 16.4z" />
  </Icon>
);

export default LoginLogout;
