// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import * as Icons from '../Icons';

// Import Styled Components
import StyledImage from '../../styles/components/Image';
import StyledVideo from '../../styles/components/Video';

export class Video extends PureComponent {
  state = {
    isLoaded: false,
    isPlaying: false,
  };

  componentDidMount() {
    const { poster } = this.props;

    this.img = new Image();
    this.img.src = poster;
    this.img.onload = this.handleImageLoad;
  }

  componentWillUnmount() {
    this.img.src = null;
    this.img.onload = null;
  }

  handleImageLoad = () => {
    this.setState({
      isLoaded: true,
    });
  };

  toggleVideo = () => {
    this.setState(prevState => ({
      isPlaying: !prevState.isPlaying,
    }));
  };

  render() {
    const { poster, caption, videoCode } = this.props;
    const { isLoaded, isPlaying } = this.state;

    return (
      <div>
        <StyledVideo>
          <StyledVideo.Image modifiers={{ isLoaded }} style={{ backgroundImage: `url(${isLoaded && poster})` }}>
            <img src={poster} alt="" />
          </StyledVideo.Image>
          <StyledVideo.Button onClick={this.toggleVideo}>
            <span>Play video</span>
            <Icons.Play />
          </StyledVideo.Button>
          <StyledVideo.Iframe modifiers={{ isPlaying }}>
            <iframe
              title={caption}
              src={`https://www.youtube.com/embed/${videoCode}?rel=0&amp;showinfo=0&amp;autoplay=${
                isPlaying ? '1' : '0'
              }`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </StyledVideo.Iframe>
        </StyledVideo>
        {caption && <StyledImage.Caption>{caption}</StyledImage.Caption>}
      </div>
    );
  }
}

Video.propTypes = {
  poster: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  videoCode: PropTypes.string.isRequired,
};

export default Video;
