// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

// Import Styled Components
import StyledRadioButtonItem from '../../styles/components/RadioButtonItem';

const RadioButtonItem = ({ name, label, value, onChange, full, info, hasError }) => (
  <Field name={name}>
    {({ field, form }) => (
      <StyledRadioButtonItem.Wrapper modifiers={{ rightPadding: info !== null }}>
        <StyledRadioButtonItem
          name={name}
          value={value}
          className={field.value === value ? 'is-active' : ''}
          modifiers={{ full, hasError }}
          onClick={e => {
            field.onChange(e);
            const newVal = e.target.getAttribute('value');

            if (onChange) onChange({ ...form.values, [name]: newVal });
          }}
        >
          {label}
        </StyledRadioButtonItem>
        {info && <span>{info}</span>}
      </StyledRadioButtonItem.Wrapper>
    )}
  </Field>
);

RadioButtonItem.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  value: PropTypes.string.isRequired,
  full: PropTypes.bool,
  hasError: PropTypes.bool,
};

RadioButtonItem.defaultProps = {
  onChange: null,
  full: false,
  info: null,
  hasError: false,
};

export default RadioButtonItem;
