// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import CmsBlock from '../CmsBlock';

// Import Styled Components
import SectionWrapper from '../../styles/objects/SectionWrapper';

const CmsBlocks = ({ blocks }) => {
  if (!blocks || !blocks.length) return false;

  return (
    <SectionWrapper>
      {blocks.map(block => (
        <CmsBlock key={block.id} {...block} />
      ))}
    </SectionWrapper>
  );
};

CmsBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
};

CmsBlocks.defaultProps = {
  blocks: null,
};

export default CmsBlocks;
