// Import Dependencies
import styled from 'styled-components';

// Import utils
import getModifier from '../../../utils/getModifier';

// Import Elements
import Actions from './Actions';
import Action from './Action';
import Fields from './Fields';
import ErrorMessage from './ErrorMessage';
import Legend from './Legend';

// Import Globals
import * as vars from '../../global/vars';

const Form = styled.div`
  width: 100%;
  max-width: 500px;

  ${props =>
    getModifier(props, 'fullWidth') &&
    `
    max-width: 100%;
  `};

  form {
    padding: ${vars.spacing.small} 0;
    width: 100%;
  }
`;

// Set elements
Form.Actions = Actions;
Form.Action = Action;
Form.ErrorMessage = ErrorMessage;
Form.Legend = Legend;
Form.Fields = Fields;

export default Form;
