// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

// Import Utilities
import getModifier from '../../../utils/getModifier';

const Container = styled.div`
  margin: 0 auto;
  max-width: ${vars.grid.maxWidth};
  padding: 0 ${vars.spacing.base};
  width: 100%;

  ${vars.breakpoint.medium`
    padding: 0 ${vars.spacing.large};
  `};

  ${props =>
    getModifier(props, 'left') &&
    `
      margin: 0;
  `};

  ${props =>
    getModifier(props, 'narrow') &&
    `
    max-width: ${vars.grid.maxWidthNarrow};
  `};

  ${props =>
    getModifier(props, 'noPadding') &&
    `
    padding: 0;
  `};

  ${props =>
    getModifier(props, 'noPadding') &&
    vars.breakpoint.medium`
    padding: 0;
  `};

  ${props =>
    getModifier(props, 'hideTitleOnMobile') &&
    `
    display: none
  `};

  ${props =>
    getModifier(props, 'hideTitleOnMobile') &&
    vars.breakpoint.small`
    display: block
  `};
`;

export default Container;
