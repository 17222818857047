// Import Dependencies
import styled from 'styled-components';

// Import vars
import * as vars from '../../global/vars';
import { hexToRgba } from '../../global/utils';
import typography from '../../global/typography';

const Notification = styled.div`
  ${typography.smallText}
  background-color: ${hexToRgba(vars.colors.black, 0.1)};
  color: ${hexToRgba(vars.colors.black, 0.8)};
  margin-top: ${vars.spacing.base};
  padding: ${vars.spacing.xSmall} ${vars.spacing.small};
  position: relative;
`;

export default Notification;
