// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledNotification from '../../styles/components/Notification';

const Notification = ({ children, modifiers, ...props }) => (
  <StyledNotification modifiers={modifiers} {...props}>
    {children}
  </StyledNotification>
);

Notification.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  modifiers: PropTypes.shape({
    error: PropTypes.bool,
  }),
};

Notification.defaultProps = {
  children: null,
  error: false,
  modifiers: {
    error: false,
  },
};

export default Notification;
