// Import dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';

// Import Components
import Form from '../Form';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Grid from '../../styles/objects/Grid';
import Section from '../../styles/objects/Section';

// Import Validators
import { isRequired, isEmail } from '../../utils/validators';

const contactFormFields = [
  {
    id: 'name',
    name: 'fields[yourName]',
    label: 'Your name',
    type: 'text',
    validate: [isRequired],
  },
  {
    id: 'email',
    name: 'fields[yourEmail]',
    label: 'Your email',
    type: 'email',
    validate: [isRequired, isEmail],
  },
  {
    id: 'phoneNumber',
    name: 'fields[phoneNumber]',
    label: 'Phone number',
    type: 'text',
  },
  {
    id: 'organisationName',
    name: 'fields[organisationName]',
    label: 'Organisation name',
    type: 'text',
  },
  {
    id: 'message',
    name: 'fields[message]',
    label: 'Message',
    type: 'textArea',
    validate: [isRequired],
  },

  {
    id: 'handle',
    name: 'handle',
    type: 'hidden',
    value: 'contactForm',
  },
];

const hiddenFields = {
  handle: 'contactForm',
};

class ContactFormBlock extends Component {
  state = {
    submitMessage: null,
    isSubmitting: null,
  };

  handleSubmit = async data => {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Accept: 'application/x-www-form-urlencoded',
      },
      crossDomain: true,
    };

    this.setState({
      isSubmitting: true,
    });

    try {
      const url = `${process.env.GATSBY_CMS_DOMAIN}/save-form`;
      const submitData = {
        ...data,
        ...hiddenFields,
      };
      const formData = qs.stringify(submitData);
      const response = await axios.post(url, formData, config);

      if (response.status === 200) {
        this.setState({
          submitMessage: 'Email sent successfully.',
        });
      } else {
        this.setState({
          submitMessage: 'There was an error, please try again.',
          isSubmitting: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { submitLabel } = this.props;
    const { submitMessage, isSubmitting } = this.state;

    return (
      <Section>
        <Container>
          <Grid>
            <Grid.Item modifiers={{ colsMedium: 5, offsetMedium: 3.5 }}>
              <Grid.Inner modifiers={{ noBottomMargin: true }}>
                <Form
                  method="post"
                  onSubmit={this.handleSubmit}
                  fields={contactFormFields}
                  submitLabel={submitLabel}
                  disableSubmit={isSubmitting}
                />
                <p>{submitMessage}</p>
              </Grid.Inner>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
}

ContactFormBlock.propTypes = {
  submitLabel: PropTypes.string.isRequired,
};

export default ContactFormBlock;
