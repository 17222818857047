// Import Dependencies
import styled from 'styled-components';

// Import Elements
import CountNumber from './CountNumber';
import Item from './Item';
import LoadMore from './LoadMore';
import Score from './Score';

// Import vars
import * as vars from '../../global/vars';

const LeadersList = styled.ul`
  text-align: left;

  ${vars.breakpoint.medium`
    column-count: 2;
    column-gap: ${vars.grid.gutter};
  `}
`;

LeadersList.Item = Item;
LeadersList.Number = CountNumber;
LeadersList.Score = Score;
LeadersList.LoadMore = LoadMore;

export default LeadersList;
