// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import * as vars from '../../global/vars';
import typography from '../../global/typography';

const InlineError = styled.label`
  ${typography.smallText}
  color: ${vars.colors.red500};
  display: block;
  margin: ${vars.spacing.xxSmall} 0 0 0;
`;

export default InlineError;
