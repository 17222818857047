// Import Dependencies
import styled from 'styled-components';

// Import Globals
import * as vars from '../../global/vars';

// Import Component
import Wysiwyg from '../Wysiwyg';

const ContactSupport = styled(Wysiwyg)`
  small {
    display: block;
    margin-bottom: ${vars.spacing.small};
  }

  a:link {
    font-size: ${vars.fontSize.small};
    font-weight: ${vars.fontWeight.bold};
  }
`;

export default ContactSupport;
