// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledContactSupport from '../../styles/components/ContactSupport';

const ContactSupport = ({ message, email, support }) => {
  return (
    <StyledContactSupport>
      {message && <small>{message}</small>}
      {support && (
        <a href={`mailto:${email}`} target="_top">
          {support}
        </a>
      )}
    </StyledContactSupport>
  );
};

ContactSupport.propTypes = {
  email: PropTypes.string.isRequired,
  message: PropTypes.string,
  support: PropTypes.string.isRequired,
};

ContactSupport.defaultProps = {
  message: null,
};

export default ContactSupport;
